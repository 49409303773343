import React, { useContext, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import SignOutButton from '../components/SignOutButton';
import { UserContext } from '../context/UserContext';
import InstructorNavBar from '../components/InstructorNavBar';
import ProfileModal from '../components/ProfileModal';
import FeedbackList from '../components/FeedbackList';
import { Form, Row, Col } from 'react-bootstrap';
import { DarkModeContext } from '../context/DarkModeContext';
import NotificationBell from '../components/NotificationBell';

import './style.css';

const FeedbackInst = () => {
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const { user } = useContext(UserContext);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedSemesterYear, setSelectedSemesterYear] = useState('');
  const [courses, setCourses] = useState([]);

  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);

  const handleCourseChange = (event) => {
    const selectedCourseValue = event.target.value;
    setSelectedCourse(selectedCourseValue);

    const firstMatchingCourse = courses.find(
      (course) =>
        `${course.course_name} ${course.course_code}` === selectedCourseValue
    );

    if (firstMatchingCourse) {
      setSelectedSemesterYear(firstMatchingCourse.id);
    } else {
      setSelectedSemesterYear('');
    }
  };

  const handleSemesterYearChange = (event) => {
    const courseId = event.target.value;
    setSelectedSemesterYear(courseId);
  };

  useEffect(() => {
    const fetchMyCourses = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/curriculum/courses/instructor/${user.id}`
        );
        if (response.ok) {
          const data = await response.json();
          setCourses(data.courses);
        } else {
          console.error('Error fetching courses');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchMyCourses();
  }, [user.id]);

  const handleProfileClick = () => {
    setIsProfileModalOpen(true);
  };

  const handleCloseProfileModal = () => {
    setIsProfileModalOpen(false);
  };

  const uniqueCourses = Array.from(
    new Map(
      courses.map((course) => [
        `${course.course_name} ${course.course_code}`,
        course,
      ])
    ).values()
  );

  return (
    <div
      className="instructor-dashboard-container"
      id="instructor-feedback-container"
    >
      <div className="instructor-view-top-container">
        <div className="logo-container">
          <NavLink to="/manage">
            <img
              src={
                isDarkMode ? '/Sofia-logo-white.png' : '/Sofia-logo-colour.png'
              }
              alt="Logo"
              className="logo"
              draggable="false"
            />
          </NavLink>
        </div>
        <div className="nav-bar-container">
          <InstructorNavBar />
        </div>
        <div className="profile-container">
          <button className="profile-user-button" onClick={handleProfileClick}>
            {user.profileImage ? (
              <img
                src={user.profileImage}
                referrerPolicy="no-referrer"
                className="profile-img"
              />
            ) : (
              <i
                className="fa-solid fa-user"
                style={{ fontSize: '25px', paddingRight: '15px' }}
              ></i>
            )}
            <span>{user.username}</span>
          </button>
          <NotificationBell />
        </div>
        <div className="signout-container">
          <SignOutButton />
        </div>
      </div>

      <div className="content-container" style={{ textAlign: 'left' }}>
        <p className="page-title">Feedback</p>

        <div className="feedback-form-inst">
          <Form className="mt-3 mb-3 back">
            <Row>
              <Col xs={12} md={3}>
                <Form.Select
                  aria-label="Select Course"
                  value={selectedCourse}
                  onChange={handleCourseChange}
                  className="my-course-select"
                  style={{ width: '100%', marginBottom: '10px' }}
                >
                  <option value="" disabled hidden>
                    Select a course
                  </option>
                  {uniqueCourses.map((course) => (
                    <option
                      key={`${course.course_name} ${course.course_code}`}
                      value={`${course.course_name} ${course.course_code}`}
                    >
                      {course.course_name} {course.course_code}
                    </option>
                  ))}
                </Form.Select>
              </Col>

              {selectedCourse && (
                <Col xs={12} md={3}>
                  <Form.Select
                    aria-label="Select Semester and Year"
                    value={selectedSemesterYear}
                    onChange={handleSemesterYearChange}
                    style={{ width: '100%', marginBottom: '20px' }}
                  >
                    <option value="" disabled hidden>
                      Select Semester and Year
                    </option>
                    {courses
                      .filter(
                        (course) =>
                          `${course.course_name} ${course.course_code}` ===
                          selectedCourse
                      )
                      .map((course) => (
                        <option key={course.id} value={course.id}>
                          {course.semester === 1
                            ? 'S1'
                            : course.semester === 2
                            ? 'S2'
                            : 'SS'}{' '}
                          {course.year}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
              )}
            </Row>
          </Form>
        </div>

        {selectedSemesterYear && (
          <FeedbackList courseId={selectedSemesterYear} />
        )}
      </div>

      <ProfileModal
        isOpen={isProfileModalOpen}
        onClose={handleCloseProfileModal}
      />
    </div>
  );
};

export default FeedbackInst;
