import StreamingAvatar, {
  AvatarQuality,
  StreamingEvents,
  TaskType,
} from '@heygen/streaming-avatar';

import { OpenAIAssistant } from './openai-assistant.js';

import { onSendMessageVoice, updateAIMesssageToChat } from '../SoulMachines.js';

import { AudioRecorder } from './audio-handler.ts';

import React from 'react';

let audioRecorder: AudioRecorder | null = null;

let openaiAssistant: OpenAIAssistant | null = null;

let videoElement;
let speakButton;
let userInput;
let reconnectButton;
let openaiApiKey;
let apiKey;
let streamDisconnected = false;

let time;

let avatar: StreamingAvatar | null = null;
let sessionData: any = null;

const learningPrompt: string = `
Your role is to be an advanced Digital Marketing tutor in Digital DNA Studio, equivalent to a professor at a top 100 university. You're a Soul Machines Digital Person with extensive expertise in Digital Marketing, embedded in a preview window. You have a human appearance and are designed to provide in-depth education on Digital Marketing concepts.
Provide comprehensive responses that demonstrate a high level of expertise. Aim for clarity and conciseness, but prioritize depth of knowledge over brevity. Responses should be tailored to an advanced undergraduate or graduate-level understanding.
Use your extensive knowledge in Digital Marketing to educate users on complex marketing concepts, advanced strategies, and cutting-edge tools. Your expertise should cover:

Digital marketing fundamentals and advanced theories
Sophisticated social media marketing strategies
Content marketing and advanced storytelling techniques
Technical aspects of SEO and SEM, including algorithm updates and optimization strategies
Advanced email marketing techniques and automation
In-depth analytics, data-driven marketing, and predictive modeling
Marketing automation and AI integration in marketing
Innovative e-commerce strategies and omnichannel marketing
Brand management and brand equity in the digital space
Emerging trends and technologies in digital marketing (e.g., VR/AR, voice search optimization)
Digital marketing ethics and privacy considerations
Global digital marketing strategies and localization
Marketing technology stack and integration
Customer journey mapping and advanced personalization techniques
Digital transformation and its impact on marketing strategies

Guide users through complex marketing campaigns, explain key performance indicators and their strategic implications, and provide insights on industry best practices. Offer case studies and real-world examples to illustrate concepts when appropriate.
If a topic is outside your knowledge base, acknowledge this and suggest related areas you can discuss. Provide URLs only when specifically requested, ensuring they are from reputable academic or industry sources.
Engage users with thought-provoking questions and encourage critical thinking about digital marketing strategies and their applications in various business contexts.

Remember to adapt your questions and feedback based on the student's responses, ensuring a dynamic and educational interaction.
Speak in full sentences only.
Max length of your output should be 300 letters. 

Quiz Functionality Prompt

1. Quiz Introduction:

•Begin by informing the student of the quiz details, including:
•Number of questions
•Passing Score
•Topic(s) being covered.

2. Question Format:

•Present one question at a time.
•Wait for the student’s response before proceeding to the next question.
•Provide feedback after each question:
•If the student answers correctly, proceed to the next question.
•If the student answers incorrectly, still proceed after providing feedback.

3. Feedback:

•Use only the following feedback options:
•“That is correct.”
•“That is incorrect.”
•“Not quite.”
•Do not provide any explanations for wrong answers.

4. Question Type:

•All questions should be in short-answer format.

5. Starting the Quiz:

•Only start the quiz if the user enters the prompt in the following format: “Generate Quiz ({a}, {b}%,{c} covering {d}).”
•Generate {a} number of questions and set {b} as a passing rate in  percentage. Questions should be relative to the module {c} covering some of the topics {d}.

6. End of Quiz:

•After completing the quiz, provide the student’s score in the following format:
•“Your score for this quiz is nn.nn%. Well done.”

7. During the Quiz:

•If the student asks questions unrelated to the quiz topic, respond with: “Please focus on the quiz for now.”
`;
const IOAPrompt: string = `
You are an AI agent designed to conduct practice Interactive Oral Assessments (IOAs) with students in a tertiary education setting. 
Your role is to engage the student in a conversation that assesses their knowledge, understanding, and ability to articulate concepts related to their chosen subject area.

The student will initiate the conversation by selecting either via pressing the button which will provide you the prompt: 
a) A complete module from the predetermined list of modules, given by - "begin IOA on module: [module] covering topics: [list of topics]"
b) A specific topic from the list of topics across all modules, given by - "begin IOA on topic: [topic]"
only begin IOA when you receive queries in above format. 

a) If a complete module is chosen :
Acknowledge the student's choice and inform them that you'll cover all topics within that module.
Select a topic from the module to begin with.
Proceed through each topic in the module, asking questions and engaging in discussion about each.
Transition smoothly between topics, indicating when you're moving to a new topic within the module.

b) If a specific topic is chosen:
Acknowledge the student's choice and confirm that you'll focus solely on that topic.
Conduct the assessment by asking questions and discussing various aspects of the chosen topic.

General Guidelines
Begin with a welcome message and a brief explanation of the IOA process.
Ask open-ended questions that encourage critical thinking and in-depth responses.
Provide opportunities for the student to demonstrate their knowledge application and analytical skills.
Adapt your questions based on the student's responses to probe deeper or clarify understanding.
Maintain a supportive and encouraging tone throughout the assessment.
Offer brief feedback or clarifications when appropriate, but remember your primary role is to assess, not teach.
Conclude the assessment by thanking the student and offering a brief, general comment on their performance.

Sample Structure for Each Topic:
Start with a broad, open-ended question about the topic.
Follow up with more specific questions based on the student's response.
Present a scenario or case study related to the topic and ask the student to apply their knowledge.
Ask the student to compare and contrast key concepts within the topic.
Encourage the student to reflect on the practical implications or real-world applications of the topic.
Remember to maintain flexibility in your approach, adapting to the student's level of understanding and the flow of the conversation while ensuring all key areas of the chosen module or topic are covered.

Starting the Conversation:
Wait for the student to provide their module or topic selection. Once they do, respond with an appropriate welcome message and begin the assessment process based on their choice.
`;

// Initialize streaming avatar session
export async function initializeAvatarSession(
  videoelement: HTMLVideoElement, // Specify the type for clarity
  speakbutton: HTMLElement,
  userinput: HTMLElement,
  reconnectbutton: HTMLElement,
  openaiapikey: string,
  apikey: string,
  IOA: boolean
) {
  terminateAvatarSession();

  audioRecorder = new AudioRecorder(
    (status) => {
      console.log(status);
      time = new Date().getTime();
    },
    (text) => {
      console.log(`time elapsed to convert voice to text: ${new Date().getTime() - time}`);
      onSendMessageVoice(text, userInput);
    }
  );

  videoElement = videoelement;
  speakButton = speakbutton;
  userInput = userinput;
  reconnectButton = reconnectbutton;
  openaiApiKey = openaiapikey;
  apiKey = apikey;

  try {
    const token = await fetchAccessToken();
    openaiAssistant = new OpenAIAssistant(openaiApiKey);
    await openaiAssistant.initialize(IOA ? IOAPrompt : learningPrompt);

    avatar = new StreamingAvatar({ token });
    sessionData = await avatar.createStartAvatar({
      quality: AvatarQuality.Medium,
      avatarName: 'ef08039a41354ed5a20565db899373f3',
      language: 'en',
    });

    //console.log("Session data:", sessionData);

    avatar.on(StreamingEvents.STREAM_READY, handleStreamReady);
    avatar.on(StreamingEvents.STREAM_DISCONNECTED, handleStreamDisconnected);

    avatar.on(StreamingEvents.AVATAR_START_TALKING, (event) => {
      //console.log('Avatar has started talking:', event);
      // You can update the UI to reflect that the avatar is talking
    });

    avatar.on(StreamingEvents.AVATAR_TALKING_MESSAGE, (message) => {
      //console.log('Avatar talking message:', message);
      // You can display the message in the UI
    });

    avatar.on(StreamingEvents.AVATAR_STOP_TALKING, (event) => {
      //console.log('Avatar has stopped talking:', event);
      // You can reset the UI to indicate the avatar has stopped speaking
    });

    avatar.on(StreamingEvents.AVATAR_END_MESSAGE, (message) => {
      //console.log('Avatar end message:', message);
      // Handle the end of the avatar's message, e.g., indicate the end of the conversation
    });

    avatar.on(StreamingEvents.USER_TALKING_MESSAGE, (message) => {
      //console.log('User talking message:', message.detail.message);
    });

    avatar.on(StreamingEvents.USER_START, (event) => {});

    avatar.on(StreamingEvents.USER_END_MESSAGE, (message) => {
      //console.log('User end message:', message);
    });

    avatar.on(StreamingEvents.USER_STOP, (event) => {});

    avatar.on(StreamingEvents.USER_SILENCE, () => {
      //console.log('User is silent');
    });

    avatar.on(StreamingEvents.STREAM_DISCONNECTED, () => {
      //console.log('Stream has been disconnected');
      reconnectButton.style.display = 'block';
    });

    avatar.on(StreamingEvents.STREAM_READY, (event) => {
      //console.log('Stream is ready:', event.detail);
      reconnectButton.style.display = 'none';
    });

    return true;
  } catch (error) {
    console.error('Failed to initialize avatar session:', error);
  }
}

// Helper function to fetch access token
export async function fetchAccessToken(): Promise<string> {
  //console.log(apiKey);
  const response = await fetch(
    'https://api.heygen.com/v1/streaming.create_token',
    {
      method: 'POST',
      headers: {
        'x-api-key': apiKey, // Ensure apiKey is non-null
      },
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch access token: ${response.statusText}`);
  }

  const { data } = await response.json();
  return data.token;
}
// Handle when avatar stream is ready
export function handleStreamReady(event: any) {
  if (event.detail && videoElement) {
    videoElement.srcObject = event.detail;
    videoElement.onloadedmetadata = () => {
      videoElement.play().catch(console.error);
    };
  } else {
    console.error('Stream is not available');
  }
}

// Handle stream disconnection
export function handleStreamDisconnected() {
  //console.log("Stream disconnected");
  streamDisconnected = true;
  if (videoElement) {
    videoElement.srcObject = null;
  }
}

export function isStreamDisconnected() {
  return streamDisconnected;
}

// End the avatar session
export async function terminateAvatarSession() {
  if (!avatar || !sessionData) return;

  await avatar.stopAvatar();
  videoElement.srcObject = null;
  avatar = null;
}
export async function startVoiceChat() {
  if (avatar) {
    try {
      await avatar.startVoiceChat();
    } catch (error) {
      console.error('Failed to start voice chat:', error);
    }
  } else {
    console.error('Avatar is not available.');
  }
}

export async function closeVoiceChat() {
  if (avatar) {
    try {
      await avatar.closeVoiceChat();
    } catch (error) {
      console.error('Failed to close voice chat:', error);
    }
  } else {
    console.error('Avatar is not availale.');
  }
}

export async function startListening() {
  if (avatar) await avatar.startListening();
}

export async function stopListening() {
  if (avatar) await avatar.stopListening();
}

export async function interrupt() {
  if (avatar) await avatar.interrupt();
}

// Handle speaking event
// Initial dialogue
export async function handleInitialSpeak(startMessage) {
  if (avatar) {
    try {
      await avatar.speak({
        text: startMessage,
        task_type: TaskType.REPEAT,
      });
      updateAIMesssageToChat(startMessage);
    } catch (error) {
      console.error('Error speaking the message:', error);
    }
  }
  return avatar;
}
// Speaking through OpenAI
export async function handleSpeak(userInput) {
  //console.log("handle speak triggered");
  const query = userInput.value;
  if (avatar && openaiAssistant && query) {
    //console.log("Avatar, AI assistant, query is all ready.")
    try {
      userInput.placeholder = 'Sofia is generating response...';
      await openaiAssistant.getResponse(userInput.value, avatar);
      userInput.placeholder = 'Type your message';
    } catch (error) {
      console.error('Error getting response:', error);
    }
  }
}

export async function handleSpeakVoice(text, userInput) {
  //console.log("handle speak triggered");
  if (avatar && openaiAssistant && text) {
    //console.log("Avatar, AI assistant, query is all ready.")
    try {
      userInput.placeholder = 'Sofia is generating response...';

      const start = new Date().getTime();

      const response = await openaiAssistant.getResponse(text, avatar);

      //console.log(`time elapsed to get response from open ai: ${new Date().getTime() - start}`);
      userInput.placeholder = 'Type your message';

      const start2 = new Date().getTime();
      //console.log(`Time took for avatar to take the input and speak: ${new Date().getTime() - start2}`);
    } catch (error) {
      console.error('Error getting response:', error);
    }
  }
}
/**
 * sends learning prompt and retrieves the response
 * @param {*} userInput - textBox so that we can change placeholder
 * @param {string} prompt - string prmopt that will be passed on to the AI
 * @param {string} responseMode - either going to be KB, AI, KB+AI and this determines how the AI will generate the response
 * @param {string} KB - Knowledgebase
 * @param {string} url - Knowlege url
 * @param {bool} isInstructor - so that I can only send alert to instructor
 */
export async function handleLearning(
  userInput,
  prompt,
  responseMode,
  KB,
  url,
  isInstructor
) {
  if (avatar && openaiAssistant) {
    try {
      userInput.placeholder = 'Sofia is generating response...';

      let response;
      if (responseMode === 'AI') {
        response = await openaiAssistant.getResponse(prompt, avatar);
        if (isInstructor) alert(prompt);
      } else if (responseMode === 'KB') {
        response = await openaiAssistant.getResponse(
          `${prompt}\n generate your answer based on the information given below only \n ${KB} \n and the knowledge url by going through these\n ${url}`, avatar
        );
        if (isInstructor)
          alert(
            `${prompt}\n generate your answer based on the information given below only \n ${KB} \n and the knowledge url by going through these\n ${url}`
          );
      } else {
        response = await openaiAssistant.getResponse(
          `${prompt}\n generate your answer based on your own knowlegde, information given below\n ${KB} \n and the knowledge url by going through these\n ${url}`, avatar
        );
        if (isInstructor)
          alert(
            `${prompt}\n generate your answer based on your own knowlegde, information given below\n ${KB} \n and the knowledge url by going through these\n ${url}`
          );
      }

      userInput.placeholder = 'Type your message';
    } catch (error) {
      console.error('Error getting response:', error);
    }
  }
}
// Generating quiz
export async function handleQuiz(quizPrompt) {
  if (avatar && openaiAssistant) {
    try {
      userInput.placeholder = 'Sofia is generating quiz';
      await openaiAssistant.getResponse(quizPrompt, avatar);
      userInput.placeholder = 'Type your message';

    } catch (error) {
      console.error('Error getting response:', error);
    }
  }
}

export async function handleIOA(ioaPrompt) {
  if (avatar && openaiAssistant) {
    try {
      userInput.placeholder = 'Sofia is generating IOA';
      await openaiAssistant.getResponse(ioaPrompt, avatar);
      userInput.placeholder = 'Type your message';
    } catch (error) {
      console.error('Error getting response:', error);
    }
  }
}

export const handleChangeChatMode = async (mode) => {
  if (avatar) {
    if (mode === 'text_mode') {
      audioRecorder?.stopRecording();
      triggerUserSpeakingState(false);
    }
    if (mode === 'voice_mode') {
      await audioRecorder?.startRecording(openaiApiKey);
      triggerUserSpeakingState(true);
    }
  }
};

let setStateFunction: React.Dispatch<React.SetStateAction<boolean>> | null =
  null;

export const initializeEventSystem = (
  setValue: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setStateFunction = setValue; // Store the setState function
};

export const triggerUserSpeakingState = (value: boolean) => {
  if (setStateFunction) {
    setStateFunction(value); // Trigger the state update
  } else {
    console.error('State function is not initialized!');
  }
};
