import OpenAI from "openai";

import StreamingAvatar, {
  AvatarQuality,
  StreamingEvents,
  TaskType,
} from '@heygen/streaming-avatar';

import { updateAIMesssageToChat } from "../SoulMachines";

export class OpenAIAssistant {
  constructor(apiKey) {
    this.client = new OpenAI({ apiKey, dangerouslyAllowBrowser: true });
    this.assistant = null;
    this.thread = null;
  }

  async initialize(instructions) {
    // Create an assistant
    this.assistant = await this.client.beta.assistants.create({
      name: "Sofia",
      instructions,
      tools: [{ type: "code_interpreter" }],
      model: "gpt-4-turbo",
    });

    // Create a thread
    this.thread = await this.client.beta.threads.create();
  }


  async getResponse(userMessage, heygenAvatar) {
    let sentenceBuffer = "";
    let fullResponse = "";

    if (!this.assistant || !this.thread) {
      throw new Error("Assistant not initialized. Call initialize() first.");
    }

    // Add user message to thread
    const message = await this.client.beta.threads.messages.create(this.thread.id, {
      role: "user",
      content: userMessage,
    });

    console.log(message);
    const run = this.client.beta.threads.runs.stream(this.thread.id, {
      assistant_id: this.assistant.id
    })
    .on('textCreated', (text) => console.log('\nassistant > '))
    .on('textDelta', async (textDelta, snapshot) => {
      sentenceBuffer += textDelta.value;



      if (/[.!?]$/.test(sentenceBuffer.trim()) && !/\d\.$/.test(sentenceBuffer.trim())) {

        heygenAvatar.speak({
          text: sentenceBuffer.trim(),
          taskType: TaskType.REPEAT,
        });
        updateAIMesssageToChat(sentenceBuffer.trim());

        fullResponse += sentenceBuffer;
        sentenceBuffer = ""; 
      }
    })
    .on('toolCallCreated', (toolCall) => console.log(`\nassistant > ${toolCall.type}\n\n`))
    .on('toolCallDelta', (toolCallDelta, snapshot) => {
      if (toolCallDelta.type === 'code_interpreter') {
        if (toolCallDelta.code_interpreter.input) {
          console.log("This is toolCallDelta: " + toolCallDelta.code_interpreter.input);
        }
        if (toolCallDelta.code_interpreter.outputs) {
          console.log("this is output: \noutput >\n");
          toolCallDelta.code_interpreter.outputs.forEach(output => {
            if (output.type === "logs") {
              console.log(`This is output log: \n${output.logs}\n`);
            }
          });
        }
      }
      
    }).on('end', () => {
      console.log("Final response:", fullResponse.trim());
    });
  }
}