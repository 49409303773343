import React, { useState, useEffect } from 'react';
import {
  Accordion,
  Badge,
  Spinner,
  Row,
  Col,
  ProgressBar,
  ListGroup,
  Table,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import './accordion.css';

const FeedbackList = ({ courseId }) => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(false);

  // Average (Per Student): fetched from the backend
  const [avgAccuracy, setAvgAccuracy] = useState(null);
  const [avgUsefulness, setAvgUsefulness] = useState(null);
  // Average (All Feedback): calculated in the frontend
  const [avgAccuracyAll, setAvgAccuracyAll] = useState(null);
  const [avgUsefulnessAll, setAvgUsefulnessAll] = useState(null);

  const [accuracyCounts, setAccuracyCounts] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });
  const [usefulnessCounts, setUsefulnessCounts] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [shouldFetchFeedback, setShouldFetchFeedback] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [dateError, setDateError] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(
    'Displaying feedback of all time'
  );

  useEffect(() => {
    setShowAnalytics(false);
    setAvgAccuracy(null);
    setAvgUsefulness(null);
    setAvgAccuracyAll(null);
    setAvgUsefulnessAll(null);
    setAccuracyCounts({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 });
    setUsefulnessCounts({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 });
    setStartDate('');
    setEndDate('');
    setFeedbacks([]);
    setHasFetched(false);
    setSearchPerformed(false);
    setDisplayMessage('Displaying feedback of all time');
  }, [courseId]);

  useEffect(() => {
    const fetchFeedbacks = async () => {
      setLoading(true);

      let url = '';
      if (startDate && endDate) {
        url = `${process.env.REACT_APP_BACKEND_URL}/users/api/feedback/${courseId}/${startDate}/${endDate}`;
      } else {
        url = `${process.env.REACT_APP_BACKEND_URL}/users/api/feedback/${courseId}`;
      }

      try {
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          const sortedFeedbacks = data.feedbacks.sort(
            (a, b) => new Date(b.submitted_at) - new Date(a.submitted_at)
          );
          setFeedbacks(sortedFeedbacks);
        } else {
          console.error('Error fetching feedback for course', courseId);
          setFeedbacks([]);
        }
      } catch (error) {
        console.error('Error:', error);
        setFeedbacks([]);
      } finally {
        setLoading(false);
        setShouldFetchFeedback(false);
        setHasFetched(true);
      }
    };

    const fetchAverages = async () => {
      try {
        let url = `${process.env.REACT_APP_BACKEND_URL}/users/api/feedback/avg/${courseId}`;
        if (startDate && endDate) {
          url = `${process.env.REACT_APP_BACKEND_URL}/users/api/feedback/avg/${courseId}/${startDate}/${endDate}`;
        }
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          setAvgAccuracy(
            data.overall_avg_accuracy_rating
              ? (data.overall_avg_accuracy_rating * 20).toFixed(2)
              : null
          );
          setAvgUsefulness(
            data.overall_avg_usefulness_rating
              ? (data.overall_avg_usefulness_rating * 20).toFixed(2)
              : null
          );
        } else {
          console.error('Error fetching averages for course', courseId);
          setAvgAccuracy(null);
          setAvgUsefulness(null);
          setAvgAccuracyAll(null);
          setAvgUsefulnessAll(null);
        }
      } catch (error) {
        console.error('Error:', error);
        setAvgAccuracy(null);
        setAvgUsefulness(null);
        setAvgAccuracyAll(null);
        setAvgUsefulnessAll(null);
      }
    };

    if (courseId && shouldFetchFeedback) {
      fetchFeedbacks();
      fetchAverages();
    }
  }, [courseId, startDate, endDate, shouldFetchFeedback]);

  const handleDateChange = (setter, newValue, otherValue, isStart) => {
    setter(newValue);
    setSearchPerformed(false);

    if (isStart) {
      if (newValue && otherValue && new Date(newValue) > new Date(otherValue)) {
        setDateError('Start date cannot be after end date');
      } else {
        setDateError('');
        setAvgAccuracy(null);
        setAvgUsefulness(null);
        setAvgAccuracyAll(null);
        setAvgUsefulnessAll(null);
        setAccuracyCounts({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 });
        setUsefulnessCounts({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 });
      }
    } else {
      if (newValue && otherValue && new Date(otherValue) > new Date(newValue)) {
        setDateError('End date cannot be before start date');
      } else {
        setDateError('');
        setAvgAccuracy(null);
        setAvgUsefulness(null);
        setAvgAccuracyAll(null);
        setAvgUsefulnessAll(null);
        setAccuracyCounts({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 });
        setUsefulnessCounts({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 });
      }
    }
  };

  useEffect(() => {
    if (feedbacks.length > 0) {
      const newAccuracyCounts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
      const newUsefulnessCounts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
      let totalAccuracy = 0;
      let totalUsefulness = 0;

      feedbacks.forEach((feedback) => {
        newAccuracyCounts[feedback.accuracy_rating]++;
        newUsefulnessCounts[feedback.usefulness_rating]++;
        totalAccuracy += feedback.accuracy_rating;
        totalUsefulness += feedback.usefulness_rating;
      });
      setAccuracyCounts(newAccuracyCounts);
      setUsefulnessCounts(newUsefulnessCounts);

      setAvgAccuracyAll(((totalAccuracy / feedbacks.length) * 20).toFixed(2));
      setAvgUsefulnessAll(
        ((totalUsefulness / feedbacks.length) * 20).toFixed(2)
      );
    }
  }, [feedbacks]);

  const Link = ({ id, children, title }) => (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id={id}>{title}</Tooltip>}
    >
      <a href="#">{children}</a>
    </OverlayTrigger>
  );

  const calculatePercentage = (count, total) => {
    return total > 0 ? ((count / total) * 100).toFixed(2) + '%' : '0%';
  };

  const handleSearchClick = () => {
    // console.log('start: ', startDate, ' end: ', endDate);
    if (!dateError) {
      setShouldFetchFeedback(true);
      setShowAnalytics(true);
      setSearchPerformed(true);

      if (startDate && endDate) {
        setDisplayMessage(
          `Displaying feedback between ${new Date(
            startDate
          ).toLocaleDateString()} and ${new Date(endDate).toLocaleDateString()}`
        );
      } else {
        setDisplayMessage('Displaying feedback of all time');
      }
    }
  };

  const submittedToday = (date) => {
    const today = new Date();
    const feedbackDate = new Date(date);
    return today.toDateString() === feedbackDate.toDateString();
  };

  const totalFeedbacks = feedbacks.length;

  return (
    <div className="feedback-list">
      {courseId && (
        <>
          <Row className="mb-2 back">
            <Col xs={12} md={5}>
              <Form.Group as={Row}>
                <Form.Label column sm={4}>
                  Start Date
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    className="feedback form-select"
                    type="date"
                    value={startDate}
                    onChange={(e) =>
                      handleDateChange(
                        setStartDate,
                        e.target.value,
                        endDate,
                        true
                      )
                    }
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={12} md={5}>
              <Form.Group as={Row}>
                <Form.Label column sm={4}>
                  End Date
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    className="feedback form-select"
                    type="date"
                    value={endDate}
                    onChange={(e) =>
                      handleDateChange(
                        setEndDate,
                        e.target.value,
                        startDate,
                        false
                      )
                    }
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col
              xs={12}
              md={4}
              className="d-flex justify-content-md-start justify-content-end mt-3 mt-md-0"
            >
              <button
                className="feedback-search-button"
                onClick={handleSearchClick}
                disabled={!!dateError}
              >
                <i className="fa-solid fa-magnifying-glass"></i>
                Search
              </button>
            </Col>
            <Row className="mt-2">
              <Col xs={12} md={8}>
                <p style={{ color: 'red' }}>{dateError}</p>
              </Col>
            </Row>
          </Row>
        </>
      )}

      {/* Display Feedback Message */}
      {showAnalytics && (
        <Row className="mt-2">
          <Col xs={12}>
            <p style={{ fontWeight: 'bold', color: '#777777' }}>
              {displayMessage}
            </p>
          </Col>
        </Row>
      )}

      {/* Feedback Analytics */}
      {showAnalytics && (
        <Row>
          <Col xs={12} md={6}>
            <ListGroup>
              <ListGroup.Item
                variant="dark"
                className="fw-bold d-flex justify-content-center"
              >
                Accuracy
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <b>Average</b> (All Feedback){' '}
                    <Link title="This average includes all feedback entries, regardless of whether they come from the same student.">
                      <i className="fa-solid fa-circle-info"></i>
                    </Link>
                  </div>
                  <div>
                    {avgAccuracyAll !== null ? avgAccuracyAll + '%' : 'N/A'}
                  </div>
                </div>
              </ListGroup.Item>

              <ListGroup.Item>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <b>Average</b> (Per Student){' '}
                    <Link title="Per Student Average: Computes average accuracy ratings for each student based on their feedback. Course Average: Averages these student averages to provide a balanced overall course rating, minimizing the effect of multiple feedbacks from a single student.">
                      <i className="fa-solid fa-circle-info"></i>
                    </Link>
                  </div>
                  <div>{avgAccuracy !== null ? avgAccuracy + '%' : 'N/A'}</div>
                </div>
              </ListGroup.Item>
            </ListGroup>

            <Table bordered className="mt-2" style={{ textAlign: 'center' }}>
              <thead>
                <tr>
                  <th>Rating</th>
                  <th>
                    Number of Feedback
                    <Link title="Displays the total number of feedback submissions by rating level (1 to 5) for accuracy. This includes all feedback entries, regardless of whether they come from the same student.">
                      <i className="fa-solid fa-circle-info"></i>
                    </Link>
                  </th>
                  <th>% of Total</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(accuracyCounts)
                  .sort((a, b) => b - a)
                  .map((level) => (
                    <tr key={`accuracy-${level}`}>
                      <td>{level}</td>
                      <td>{accuracyCounts[level]}</td>
                      <td>
                        {calculatePercentage(
                          accuracyCounts[level],
                          totalFeedbacks
                        )}
                      </td>
                    </tr>
                  ))}

                <tr className="fw-bold">
                  <td>Total</td>
                  <td>{totalFeedbacks}</td>
                  <td>100%</td>
                </tr>
              </tbody>
            </Table>
          </Col>

          <Col xs={12} md={6}>
            <ListGroup>
              <ListGroup.Item
                variant="dark"
                className="fw-bold d-flex justify-content-center"
              >
                Usefulness
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <b>Average</b> (All Feedback){' '}
                    <Link title="This average includes all feedback entries, regardless of whether they come from the same student.">
                      <i className="fa-solid fa-circle-info"></i>
                    </Link>
                  </div>
                  <div>
                    {avgUsefulnessAll !== null ? avgUsefulnessAll + '%' : 'N/A'}
                  </div>
                </div>
              </ListGroup.Item>

              <ListGroup.Item>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <b>Average</b> (Per Student){' '}
                    <Link title="Per Student Average: Computes average usefulness ratings for each student based on their feedback. Course Average: Averages these student averages to provide a balanced overall course rating, minimizing the effect of multiple feedbacks from a single student.">
                      <i className="fa-solid fa-circle-info"></i>
                    </Link>
                  </div>
                  <div>
                    {avgUsefulness !== null ? avgUsefulness + '%' : 'N/A'}
                  </div>
                </div>
              </ListGroup.Item>
            </ListGroup>

            <Table bordered className="mt-2" style={{ textAlign: 'center' }}>
              <thead>
                <tr>
                  <th>Rating</th>
                  <th>
                    Number of Feedback
                    <Link title="Displays the total number of feedback submissions by rating level (1 to 5) for usefulness. This includes all feedback entries, regardless of whether they come from the same student.">
                      <i className="fa-solid fa-circle-info"></i>
                    </Link>
                  </th>
                  <th>% of Total</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(usefulnessCounts)
                  .sort((a, b) => b - a)
                  .map((level) => (
                    <tr key={`usefulness-${level}`}>
                      <td>{level}</td>
                      <td>{usefulnessCounts[level]}</td>
                      <td>
                        {calculatePercentage(
                          usefulnessCounts[level],
                          totalFeedbacks
                        )}
                      </td>
                    </tr>
                  ))}

                <tr className="fw-bold">
                  <td>Total</td>
                  <td>{totalFeedbacks}</td>
                  <td>100%</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      )}

      {loading ? (
        <div style={{ textAlign: 'center', paddingTop: '20px' }}>
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <>
          {hasFetched && (
            <p
              style={{
                paddingTop: '10px',
                fontWeight: 'bold',
                color: '#777777',
              }}
            >
              <span style={{ marginLeft: '10px' }}></span>
              {totalFeedbacks} found
            </p>
          )}

          {feedbacks.length > 0 && (
            <Accordion className="mb-3 mt-3 pb-3">
              {feedbacks.map((feedback) => (
                <Accordion.Item key={feedback.id} eventKey={feedback.id}>
                  <Accordion.Header>
                    <div className="d-flex w-100 align-items-center">
                      <div>
                        <b className="feedback-student-name">
                          {feedback.student_first_name}{' '}
                          {feedback.student_last_name}
                        </b>
                        {submittedToday(feedback.submitted_at) && (
                          <Badge pill bg="secondary" className="ms-3">
                            New
                          </Badge>
                        )}
                      </div>
                      <div className="ms-auto">
                        <p className="mb-0 me-2 feedback-submitted-at">
                          {new Date(feedback.submitted_at).toLocaleDateString()}{' '}
                          {new Date(feedback.submitted_at).toLocaleTimeString()}
                        </p>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row className="mb-2">
                      <Col xs={12} md={6} className="d-flex">
                        <div className="feedback-heading">
                          <p>
                            <b>Accuracy</b>
                          </p>
                        </div>
                        <div
                          className="ms-3 w-100"
                          style={{ marginTop: '4px' }}
                        >
                          <ProgressBar
                            variant="rating-bar"
                            now={(feedback.accuracy_rating / 5) * 100}
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={6} className="d-flex">
                        <div className="feedback-heading">
                          <p>
                            <b>Usefulness</b>
                          </p>
                        </div>
                        <div
                          className="ms-3 w-100"
                          style={{ marginTop: '4px' }}
                        >
                          <ProgressBar
                            variant="rating-bar"
                            now={(feedback.usefulness_rating / 5) * 100}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} className="feedback-heading">
                        <p>
                          <b>Feedback/Suggestions</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <p>{feedback.feedback_text}</p>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          )}
        </>
      )}
    </div>
  );
};

export default FeedbackList;
