import React, { useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import SignOutButton from '../components/SignOutButton';
import { UserContext } from '../context/UserContext';
import InstructorNavBar from '../components/InstructorNavBar';
import ProfileModal from '../components/ProfileModal';
import NotificationCourseSelect from '../components/NotificationCourseSelect';
import AllCoursesList from '../components/AllCoursesList';
import { DarkModeContext } from '../context/DarkModeContext';
import TrainingGroundInst from '../components/TrainingGroundInst';
import NotificationBell from '../components/NotificationBell';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './style.css';
// NotificationDirectPage.js

const NotificationDirectPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [reloadMyCourses, setReloadMyCourses] = useState(false);
  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);
  const [isTrainingGroundActive, setIsTrainingGroundActive] = useState(false);

  const handleProfileClick = () => {
    setIsProfileModalOpen(true);
  };

  const handleCloseProfileModal = () => {
    setIsProfileModalOpen(false);
  };
  const { selectedCourse, selectedSubtopic } = location.state || {};

  if (!selectedCourse) {
    return (
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <p>No course data found. Please select a notification first.</p>
        <button onClick={() => navigate(-1)}>Go Back</button>
      </div>
    );
  }

  return (
    <div className="instructor-dashboard-container " id="courses-container">
      <div className="instructor-view-top-container">
        <div className="logo-container">
          <NavLink to="/manage">
            <img
              src={
                isDarkMode ? '/Sofia-logo-white.png' : '/Sofia-logo-colour.png'
              }
              alt="Logo"
              className="logo"
              draggable="false"
            />
          </NavLink>
        </div>
        <div className="nav-bar-container">
          <InstructorNavBar />
        </div>
        <div className="profile-container">
          <button className="profile-user-button" onClick={handleProfileClick}>
            {user.profileImage ? (
              <img
                src={user.profileImage}
                referrerPolicy="no-referrer"
                className="profile-img"
              />
            ) : (
              <i
                className="fa-solid fa-user"
                style={{ fontSize: '25px', paddingRight: '15px' }}
              ></i>
            )}
            <span>{user.username}</span>
          </button>
          <NotificationBell />
        </div>
        <div className="signout-container">
          <SignOutButton />
        </div>
      </div>

      <div className="content-container" style={{ padding: '10px 120px' }}>
        <button
          className="back-button"
          onClick={() => navigate(-1)}
          style={{ marginBottom: '20px' }}
        >
          <i className="fa-solid fa-chevron-left"></i> Back
        </button>
        <TrainingGroundInst
          selectedCourse={selectedCourse}
          selectedSubtopic={selectedSubtopic}
        />
      </div>

      <ProfileModal
        isOpen={isProfileModalOpen}
        onClose={handleCloseProfileModal}
      />
    </div>
  );
};

export default NotificationDirectPage;
