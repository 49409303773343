import React, { useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import SignOutButton from '../components/SignOutButton';
import { UserContext } from '../context/UserContext';
import InstructorNavBar from '../components/InstructorNavBar';
import ProfileModal from '../components/ProfileModal';
import MyCoursesListInst from '../components/MyCoursesListInst';
import AllCoursesList from '../components/AllCoursesList';
import { DarkModeContext } from '../context/DarkModeContext';

import NotificationBell from '../components/NotificationBell';

import './style.css';

const Courses = () => {
  const { user } = useContext(UserContext);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [reloadMyCourses, setReloadMyCourses] = useState(false);
  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);

  const handleProfileClick = () => {
    setIsProfileModalOpen(true);
  };

  const handleCloseProfileModal = () => {
    setIsProfileModalOpen(false);
  };

  const Link = ({ id, children, title }) => (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id={id}>{title}</Tooltip>}
    >
      <a href="#">{children}</a>
    </OverlayTrigger>
  );

  const [showAllCourses, setShowAllCourses] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [courseName, setCourseName] = useState('');
  const [courseCode, setCourseCode] = useState('');
  const [courseYear, setCourseYear] = useState('');
  const [courseSemester, setCourseSemester] = useState('');
  const [courseDescription, setCourseDescription] = useState('');
  const [instructorEmails, setInstructorEmails] = useState(['']);
  const [csvFile, setCsvFile] = useState(null);

  const currentYear = new Date().getFullYear();
  const years = [currentYear, currentYear + 1, currentYear + 2];

  const handleAddCourse = () => {
    setIsFormVisible((prev) => !prev);
  };

  const handleInstructorEmailChange = (index, event) => {
    const newEmails = [...instructorEmails];
    newEmails[index] = event.target.value;
    setInstructorEmails(newEmails);
  };

  const handleAddEmailField = () => {
    if (instructorEmails.length < 5) {
      setInstructorEmails([...instructorEmails, '']);
    }
  };

  const handleRemoveEmailField = (index) => {
    if (instructorEmails.length > 1) {
      const newEmails = instructorEmails.filter((_, i) => i !== index);
      setInstructorEmails(newEmails);
    }
  };

  const handleViewAllCourses = () => {
    setShowAllCourses(!showAllCourses);
  };

  const handleFileChange = (event) => {
    setCsvFile(event.target.files[0]);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (instructorEmails.every((email) => email.trim() === '')) {
      alert('At least one instructor email is required');
      return;
    }

    const newCourseData = new FormData();
    newCourseData.append('course_name', courseName.toUpperCase());
    newCourseData.append('course_code', courseCode.toUpperCase());
    newCourseData.append('course_description', courseDescription);
    newCourseData.append('course_year', courseYear);
    newCourseData.append('course_semester', courseSemester);
    instructorEmails.forEach((email, index) => {
      if (email.trim() !== '') {
        newCourseData.append('instructor_emails', email);
      }
    });
    if (csvFile) {
      newCourseData.append('csv_file', csvFile);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/add-course`,
        {
          method: 'POST',
          body: newCourseData,
        }
      );

      if (response.ok) {
        setCourseName('');
        setCourseCode('');
        setCourseDescription('');
        setCourseYear('');
        setCourseSemester('');
        setInstructorEmails(['']);
        setCsvFile(null);
        setIsFormVisible(false);
        setShowAllCourses(false);
        setReloadMyCourses((prev) => !prev);
      } else {
        const errorData = await response.json();

        alert(errorData.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="instructor-dashboard-container" id="courses-container">
      <div className="instructor-view-top-container">
        <div className="logo-container">
          <NavLink to="/manage">
            <img
              src={
                isDarkMode ? '/Sofia-logo-white.png' : '/Sofia-logo-colour.png'
              }
              alt="Logo"
              className="logo"
              draggable="false"
            />
          </NavLink>
        </div>
        <div className="nav-bar-container">
          <InstructorNavBar />
        </div>
        <div className="profile-container">
          <button className="profile-user-button" onClick={handleProfileClick}>
            {user.profileImage ? (
              <img
                src={user.profileImage}
                referrerPolicy="no-referrer"
                className="profile-img"
              />
            ) : (
              <i
                className="fa-solid fa-user"
                style={{ fontSize: '25px', paddingRight: '15px' }}
              ></i>
            )}
            <span>{user.username}</span>
          </button>
          <NotificationBell />
        </div>
        <div className="signout-container">
          <SignOutButton />
        </div>
      </div>

      <div className="content-container">
        <p
          className="page-title"
          style={{ display: 'inline-block', marginRight: '5px' }}
        >
          My Courses
        </p>
        <p style={{ display: 'inline-block', marginBottom: '0' }}>
          <Link title="This section lists the courses you instruct. 'All Courses' below includes every course available in the system.">
            <i
              className="fa-solid fa-circle-info"
              style={{ cursor: 'pointer' }}
            ></i>
          </Link>
        </p>

        <MyCoursesListInst reload={reloadMyCourses} />
        <div className="course-actions">
          <button
            className="list-all-courses-button"
            onClick={handleViewAllCourses}
          >
            <i
              className={
                showAllCourses ? 'fa-solid fa-eye-slash' : 'fa-regular fa-eye'
              }
            ></i>
            {showAllCourses ? ' Hide All Courses' : ' View All Courses'}
          </button>
          <button
            type="button"
            className="add-course-button"
            onClick={handleAddCourse}
          >
            <i className="fa-solid fa-plus"></i> Add Course
          </button>
        </div>

        {isFormVisible && (
          <div className="add-course-form">
            <Form className="mt-3 mt-md-4 w-100" onSubmit={handleFormSubmit}>
              <Form.Group as={Row} className="mb-3" controlId="formCourseName">
                <Form.Label column sm="2">
                  Course
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="e.g. MKTG"
                    value={courseName}
                    onChange={(e) => setCourseName(e.target.value)}
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="formCourseCode">
                <Form.Label column sm="2">
                  Code
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="e.g. 304"
                    value={courseCode}
                    onChange={(e) => setCourseCode(e.target.value)}
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formCourseDescription"
              >
                <Form.Label column sm="2">
                  Description
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="e.g. Digital Marketing"
                    value={courseDescription}
                    onChange={(e) => setCourseDescription(e.target.value)}
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="formCourseYear">
                <Form.Label column sm="2">
                  Year
                </Form.Label>
                <Col sm="10">
                  <Form.Select
                    value={courseYear}
                    onChange={(e) => setCourseYear(e.target.value)}
                    required
                  >
                    <option value="" disabled hidden>
                      Select
                    </option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formCourseSemester"
              >
                <Form.Label column sm="2">
                  Semester
                </Form.Label>
                <Col sm="10">
                  <Form.Select
                    value={courseSemester}
                    onChange={(e) => setCourseSemester(e.target.value)}
                    required
                  >
                    <option value="" disabled hidden>
                      Select
                    </option>
                    <option value="1">Quarter 1</option>
                    <option value="2">Quarter 2</option>
                    <option value="3">Quarter 3</option>
                    <option value="4">Quarter 4</option>
                    <option value="5">Semester 1</option>
                    <option value="6">Semester 2</option>
                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formCourseInstructors"
              >
                <Form.Label column sm="2">
                  Instructor Emails{' '}
                  <Link
                    title="Instructor email must be registered in the system. Please ensure the instructor is registered before adding them to the course."
                    id="instructor-info"
                  >
                    <i className="fa-solid fa-circle-info"></i>
                  </Link>{' '}
                </Form.Label>
                <Col sm="10">
                  {instructorEmails.map((email, index) => (
                    <div key={index} className="email-input-row mb-2">
                      <Row className="align-items-center">
                        <Col xs="10" sm="11" className="pe-1">
                          <Form.Control
                            type="email"
                            placeholder="example@gmail.com"
                            value={email}
                            onChange={(e) =>
                              handleInstructorEmailChange(index, e)
                            }
                          />
                        </Col>
                        <Col
                          xs="2"
                          sm="1"
                          className="d-flex justify-content-end"
                        >
                          {instructorEmails.length > 1 && (
                            <button
                              type="button"
                              className="email-remove-button"
                              onClick={() => handleRemoveEmailField(index)}
                            >
                              <i className="fa-solid fa-minus"></i>
                            </button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  ))}
                  {instructorEmails.length < 5 && (
                    <Row className="email-add-row mt-2">
                      <Col sm="1">
                        <button
                          type="button"
                          className="email-add-button"
                          onClick={handleAddEmailField}
                        >
                          <i className="fa-solid fa-plus"></i>
                        </button>
                      </Col>
                      <Col sm="11"></Col>
                    </Row>
                  )}
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-5"
                controlId="formCourseRoster"
              >
                <Form.Label column sm="2">
                  Student Roster{' '}
                  <Link title="Please upload a CSV file downloaded from Canvas. The file should include Student Name and Email.">
                    <i className="fa-solid fa-circle-info"></i>
                  </Link>{' '}
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange}
                  />
                </Col>
              </Form.Group>

              <div className="add-course-submit-container">
                <button type="submit" className="add-course-submit-button">
                  Submit
                </button>
              </div>
            </Form>
          </div>
        )}

        {showAllCourses && <AllCoursesList />}
      </div>

      <ProfileModal
        isOpen={isProfileModalOpen}
        onClose={handleCloseProfileModal}
      />
    </div>
  );
};

export default Courses;
