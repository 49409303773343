import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SignInModal from '../components/SignInModal';
import './Home.css';

const Home = () => {
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);
  const images = ['/homepage1.png', '/homepage2.png', '/homepage3.png'];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);
  const openSignInModal = () => {
    const authToken = sessionStorage.getItem('authToken');
    if (authToken) {
      if (sessionStorage.getItem('role') === 'student') {
        navigate('/learning');
      } else if (sessionStorage.getItem('role') === 'instructor') {
        navigate('/manage');
      }
    } else {
      setIsSignInOpen(true);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const closeSignInModal = () => {
    setIsSignInOpen(false);
  };

  const mobileMessage =
    'Prepare for lectures and review with ease using our AI tutor, designed for University of Auckland students. Get real-time feedback, interactive support, and personalized insights to enhance your learning experience. Stay ahead in your studies with the power of AI—start today!';
  const desktopMessage =
    'Prepare for lectures and review with ease using our AI tutor, designed for University of Auckland students. Get real-time feedback, interactive support, and personalized insights to enhance your learning experience. Stay ahead in your studies with the power of AI—start today!';

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="home-container">
      <div className="home-top-container">
        <img
          src="/Sofia-logo-white.png"
          alt="Logo"
          className="home-logo"
          draggable="false"
        />
      </div>{' '}
      <div className="home-text-container">
        <h1 id="home-welcome" draggable="false">
          AI-Powered Learning, <br></br>Tailored for You
        </h1>
        <br></br>

        <p id="home-description" draggable="false">
          {isMobileView ? mobileMessage : desktopMessage}
        </p>
      </div>
      <div className="image-container">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index}`}
            className="homepage"
            draggable="false"
            style={{
              width: '600px',
              height: '440px',
              position: 'absolute',
              right: '0',
              top: '50%',
              transform: 'translateY(-50%)',
              opacity: index === currentImageIndex ? 1 : 0,
              transition: 'opacity 1s ease-in-out',
              borderRadius: '20px 0px 0px 20px',
            }}
          />
        ))}
      </div>
      <div className="home-button-container">
        <button
          onClick={openSignInModal}
          className="home-button"
          id="signin-button"
        >
          Sign In
        </button>
      </div>
      {isSignInOpen && <SignInModal closeModal={closeSignInModal} />}
    </div>
  );
};

export default Home;
