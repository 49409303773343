import React, { useState, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import StudentNavBar from '../components/StudentNavBar';
import { UserContext } from '../context/UserContext';
import '@fortawesome/fontawesome-free/css/all.min.css';
import SignOutButton from '../components/SignOutButton';
import ProfileModal from '../components/ProfileModal';
import { Form, Spinner } from 'react-bootstrap';
import { DarkModeContext } from '../context/DarkModeContext';

import './style.css';

const Feedback = () => {
  const { user } = useContext(UserContext);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);
  const handleProfileClick = () => {
    setIsProfileModalOpen(true);
  };

  const handleCloseProfileModal = () => {
    setIsProfileModalOpen(false);
  };

  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedAccuracyRating, setSelectedAccuracyRating] = useState(null);
  const [selectedUsefulnessRating, setSelectedUsefulnessRating] =
    useState(null);
  const [feedbackText, setFeedbackText] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchMyCourses = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/curriculum/courses/student/${user.id}`
        );
        if (response.ok) {
          const data = await response.json();
          setCourses(data.courses);
        } else {
          console.error('Error fetching courses');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchMyCourses();
  }, [user.id]);

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
  };

  const handleAccuracyRatingClick = (index) => {
    setSelectedAccuracyRating(index);
  };

  const handleUsefulnessRatingClick = (index) => {
    setSelectedUsefulnessRating(index);
  };

  const handleFeedbackChange = (event) => {
    setFeedbackText(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      selectedCourse === '' ||
      selectedAccuracyRating === null ||
      selectedUsefulnessRating === null ||
      feedbackText.trim() === ''
    ) {
      alert('Please complete all questions before submitting!');
      return;
    }

    const feedbackData = {
      userId: user.id,
      courseId: selectedCourse,
      accuracyRating: selectedAccuracyRating + 1,
      usefulnessRating: selectedUsefulnessRating + 1,
      feedbackText: feedbackText,
    };

    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/users/api/feedback/submit/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(feedbackData),
        }
      );

      if (response.ok) {
        alert('Thank you for your feedback!');
        setSelectedCourse('');
        setSelectedAccuracyRating(null);
        setSelectedUsefulnessRating(null);
        setFeedbackText('');
      } else {
        const errorData = await response.json();
        console.error('Error submitting feedback:', errorData);
        alert('There was an error submitting your feedback. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('There was an error submitting your feedback. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="student-dashboard-container"
      id="student-feedback-container"
    >
      <div className="student-view-top-container">
        <div className="logo-container">
          <NavLink to="/learning">
            <img
              src={
                isDarkMode ? '/Sofia-logo-white.png' : '/Sofia-logo-colour.png'
              }
              alt="Logo"
              className="logo"
              draggable="false"
            />
          </NavLink>
        </div>
        <div className="nav-bar-container">
          <StudentNavBar />
        </div>
        <div className="profile-container">
          <button className="profile-user-button" onClick={handleProfileClick}>
            {user.profileImage ? (
              <img
                src={user.profileImage}
                referrerPolicy="no-referrer"
                className="profile-img"
              />
            ) : (
              <i
                className="fa-solid fa-user"
                style={{ fontSize: '25px', paddingRight: '15px' }}
              ></i>
            )}
            <span>{user.username}</span>
          </button>
        </div>
        <div className="signout-container">
          <SignOutButton />
        </div>
      </div>

      <div style={{ paddingBottom: '5px' }}>
        <div className="feedback-form-container">
          {courses.length > 0 ? (
            <Form>
              <h2 id="student-feedback-title">✨ Provide Your Feedback ✨</h2>
              <h2 id="student-feedback-title-new">✨ Feedback ✨</h2>

              <Form.Select
                className="my-course-select"
                value={selectedCourse}
                onChange={handleCourseChange}
                style={{ margin: '0 auto 40px', display: 'block' }}
              >
                <option value="" disabled hidden>
                  Select a course
                </option>
                {courses.map((course) => (
                  <option key={course.id} value={course.id}>
                    {course.course_name} {course.course_code} (
                    {course.semester === 1
                      ? 'S1'
                      : course.semester === 2
                      ? 'S2'
                      : 'SS'}{' '}
                    {course.year})
                  </option>
                ))}
              </Form.Select>

              <p>
                How accurately did Sofia understand and respond to your digital
                marketing questions?
              </p>
              <div className="feedback-icons">
                <i
                  className={`fa-regular fa-face-angry ${
                    selectedAccuracyRating === 0 ? 'selected' : ''
                  }`}
                  onClick={() => handleAccuracyRatingClick(0)}
                ></i>
                <i
                  className={`fa-regular fa-face-frown ${
                    selectedAccuracyRating === 1 ? 'selected' : ''
                  }`}
                  onClick={() => handleAccuracyRatingClick(1)}
                ></i>
                <i
                  className={`fa-regular fa-face-meh ${
                    selectedAccuracyRating === 2 ? 'selected' : ''
                  }`}
                  onClick={() => handleAccuracyRatingClick(2)}
                ></i>
                <i
                  className={`fa-regular fa-face-smile ${
                    selectedAccuracyRating === 3 ? 'selected' : ''
                  }`}
                  onClick={() => handleAccuracyRatingClick(3)}
                ></i>
                <i
                  className={`fa-regular fa-face-grin ${
                    selectedAccuracyRating === 4 ? 'selected' : ''
                  }`}
                  onClick={() => handleAccuracyRatingClick(4)}
                ></i>
              </div>

              <p>
                How useful was Sofia in guiding you through the learning path
                and enhancing your understanding of digital marketing concepts?
              </p>
              <div className="feedback-icons">
                <i
                  className={`fa-regular fa-face-angry ${
                    selectedUsefulnessRating === 0 ? 'selected' : ''
                  }`}
                  onClick={() => handleUsefulnessRatingClick(0)}
                ></i>
                <i
                  className={`fa-regular fa-face-frown ${
                    selectedUsefulnessRating === 1 ? 'selected' : ''
                  }`}
                  onClick={() => handleUsefulnessRatingClick(1)}
                ></i>
                <i
                  className={`fa-regular fa-face-meh ${
                    selectedUsefulnessRating === 2 ? 'selected' : ''
                  }`}
                  onClick={() => handleUsefulnessRatingClick(2)}
                ></i>
                <i
                  className={`fa-regular fa-face-smile ${
                    selectedUsefulnessRating === 3 ? 'selected' : ''
                  }`}
                  onClick={() => handleUsefulnessRatingClick(3)}
                ></i>
                <i
                  className={`fa-regular fa-face-grin ${
                    selectedUsefulnessRating === 4 ? 'selected' : ''
                  }`}
                  onClick={() => handleUsefulnessRatingClick(4)}
                ></i>
              </div>

              <div className="separator"></div>
              <p>
                What aspects of Sofia's interaction did you find most helpful?
                Where do you think Sofia could improve to better assist you in
                learning digital marketing?
              </p>
              <textarea
                className="feedback-textarea"
                placeholder="Please mention specific features or areas where Sofia excelled or could improve. Feel free to describe any challenges you faced or suggestions for enhancing the interaction."
                maxLength="1000"
                value={feedbackText}
                onChange={handleFeedbackChange}
              ></textarea>
              <div className="char-count">
                {feedbackText.length} / 1000 characters
              </div>
              <button
                type="button"
                className="feedback-submit-button"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <div style={{ textAlign: 'center' }}>
                    <Spinner animation="border" variant="secondary" />
                  </div>
                ) : (
                  'Submit'
                )}
              </button>
            </Form>
          ) : (
            <p
              style={{
                textAlign: 'center',
                color: '#777777',
                marginBottom: '0',
              }}
            >
              You currently don't have any courses available for feedback.
              <br />
              If you think this is an error, please reach out to our support
              team for help.
            </p>
          )}
        </div>
      </div>

      <ProfileModal
        isOpen={isProfileModalOpen}
        onClose={handleCloseProfileModal}
      />
    </div>
  );
};

export default Feedback;
