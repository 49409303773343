import { Scene, Persona, ConversationState, ConversationStateTypes } from '@soulmachines/smwebsdk';
import { handleSpeak,handleSpeakVoice, handleLearning, handleQuiz, handleIOA} from './heygen/main.ts';

//const [showButtons, setShowButtons] = useState(true);

const apiKeyLearning = process.env.REACT_APP_SOUL_MACHINES_API_KEY_LEARNING;
const apiKeyIOA = process.env.REACT_APP_SOUL_MACHINES_API_KEY_IOA;
//console.log(apiKeyLearning);
//console.log(apiKeyIOA);
let apiKEY = apiKeyLearning;

let scene;
let sessionId;
let persona;
// let listenersAdded = false;
let chat;
let videoEl;
let SMBool = { upload: true };
let userBool = { upload: true };
let isQuiz = true;
let passingScore;
let quizQuestions;
let turns = 0;
let startTime;
let current_module;
let currentCourseId;
let currentStudentId;

let userId;
let currentModuleId;
let quizStartTime;
let quizEndTime;

let sendBox;
let sendButton;

export let transcript = [];

export const toggleIOA = async (IOA) => {
  //console.log('switching');
  return !IOA;
};

export function deactivateKeyboardInput(textbox, send) {
  //console.log('HI');
  sendBox = textbox;
  sendButton = send;
  sendBox.disabled = true;
  sendButton.disabled = true;
  chat.style.userSelect = 'none';
}

export function activateKeyboardInput(sendbox, sendbutton) {
  //console.log('HI');
  sendBox = sendbox;
  sendButton = sendbutton;
  sendBox.disabled = false;
  sendButton.disabled = false;
  chat.style.userSelect = 'text';
  const listItems = chat.querySelectorAll('li');
  listItems.forEach((item) => {
    item.style.userSelect = 'text';
  });
}

export function getCurrentTime() {
  return new Date().getTime() - startTime;
}

export function getTurns() {
  return turns;
}

//-------------------------------------------------------------
//-------------------------------------------------------------
//-------------------------------------------------------------
//-------------------------------------------------------------
// NEW HEYGEN RELATED FUNCTIONS
//-------------------------------------------------------------
//-------------------------------------------------------------
//-------------------------------------------------------------
//-------------------------------------------------------------

function scrollToBottom() {
  chat.scrollTop = chat.scrollHeight;
}
export function initializeAI(v, chatbox, course_id, student_id) {
  currentCourseId = course_id;
  currentStudentId = student_id;
  startTime = new Date().getTime()
  if (transcript != null) transcript = [];
  if (chat != null) {
    chat.innerHTML = '';
    chat = null;
  }
  if (videoEl != null) videoEl = null;
  chat = chatbox;
  videoEl = v;
}

export function updateAIData(courseId, studentId) {
  //console.log('updating AI data.......');
  const EndTime = new Date().getTime();
  currentCourseId = courseId;
  currentStudentId = studentId;

  const requestData = {
    student_id: currentStudentId,
    course_id: currentCourseId,
    turns: turns,
    start_time: startTime,
    end_time: EndTime,
  };

  startTime = EndTime;
  //console.log('requestData: ', JSON.stringify(requestData));

  fetch(`${process.env.REACT_APP_BACKEND_URL}/analytics/update-ai-data`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => response.json())
    .then((data) => {
      //console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}

export function onSendMessage(textInput) {
  const userText = textInput.value;

  if (userText.length > 0) {

    transcript.push({ source: 'user', text: userText });
    const userDiv = document.createElement('div');
    userDiv.classList.add('chat-bubble', 'user');
    // userDiv.innerHTML = `<strong>You:</strong> ${userText}`;
    userDiv.innerHTML = `${userText}`;
    chat.appendChild(userDiv);
    scrollToBottom();
    //console.log('[transcript]: ', transcript);
    // soul machine
    //persona.conversationSend(userText, {}, {});

    // heygen
    handleSpeak(textInput);
    //console.log('[userText] user typed:', userText);

    //console.log('spurce: ' + transcript[transcript.length - 1].source);
    if (
      transcript.length > 0 &&
      transcript[transcript.length - 1].source === 'persona'
    ) {
      turns += 1;
    }
  }

  textInput.value = '';
}

export function onSendMessageVoice(text, userInput) {
  if (text.length > 0) {
    transcript.push({ source: 'user', text: text });
    const userDiv = document.createElement('div');
    userDiv.classList.add('chat-bubble', 'user');
    userDiv.innerHTML = `${text}`;
    chat.appendChild(userDiv);
    scrollToBottom();
    //console.log('[transcript]: ', transcript);
    handleSpeakVoice(text,userInput);
    //console.log('[userText] user typed:', text);

    //console.log('spurce: ' + transcript[transcript.length - 1].source);
    if (
      transcript.length > 0 &&
      transcript[transcript.length - 1].source === 'persona'
    ) {
      turns += 1;
    }
  }
}
/**
 * 
 * @param {string} prompt - prompt that will be sent to the AI model 
 * @param {string} defaultPrompt = default prompt: I want to learn about "" in digital marketing.
 * @param {number} course_id - id of the course
 * @param {number} student_id - id of the student 
 * @param {*} textInputBox - userinput box so that we can change the placeholders
 * @param {string} responseMode - either going to be KB, AI, KB+AI and this determines how the AI will generate the response
 * @param {string} KB - Knowledgebase
 * @param {string} url - Knowlege url
 * @param {bool} isInstructor - to determine if it is instructor or not
 */
export function onSendMessageTopic(prompt, defaultPrompt, course_id, student_id, textInputBox, responseMode, KB, url, isInstructor) {
  //console.log(textInputBox);
  //console.log("This is default prompt: ", defaultPrompt);
  currentCourseId = course_id;
  currentStudentId = student_id;
  if (isQuiz) {
      //heygen 
      handleLearning(textInputBox, prompt, responseMode, KB, url, isInstructor);


      //console.log('[userText] user typed:', prompt);

      if (isInstructor){
        transcript.push({ source: 'Learning', text: prompt });
        const userDiv = document.createElement('div');
        userDiv.classList.add('chat-bubble', 'user');
        // userDiv.innerHTML = `<strong>You:</strong> ${userText}`;
        userDiv.innerHTML = `${prompt}`;
        chat.appendChild(userDiv);
      }
      else{
        transcript.push({ source: 'Learning', text: defaultPrompt });
        const userDiv = document.createElement('div');
        userDiv.classList.add('chat-bubble', 'user');
        // userDiv.innerHTML = `<strong>You:</strong> ${userText}`;
        userDiv.innerHTML = `${defaultPrompt}`;
        chat.appendChild(userDiv);
      }
      scrollToBottom();
      //console.log('[transcript]: ', transcript);
  }
}

function convertToBold(text) {
  return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
}

export function updateAIMesssageToChat(AIResponse){
  //console.log('[AI speech]', AIResponse);

  const formattedText = convertToBold(AIResponse);

  transcript.push({ source: 'persona', text: AIResponse });

  const personaContainer = document.createElement('div');
  personaContainer.classList.add('chat-bubble-container');

  const nameDiv = document.createElement('div');
  nameDiv.classList.add('persona-name');
  nameDiv.innerText = 'Sofia';

  const personaDiv = document.createElement('div');
  personaDiv.classList.add('chat-bubble', 'persona');
  personaDiv.innerHTML = `${formattedText}`;

  personaContainer.appendChild(nameDiv);
  personaContainer.appendChild(personaDiv);

  chat.appendChild(personaContainer);
  const topPosition = personaContainer.getBoundingClientRect().top - chat.getBoundingClientRect().top + chat.scrollTop;
  chat.scrollTo({ top: topPosition, behavior: 'smooth' });

  //console.log('[transcript]: ', transcript);

  if (
    isQuiz === false &&
    AIResponse.toLowerCase().includes('your score for this quiz')
  ) {
    isQuiz = true;
    let scoreMatch = AIResponse.match(/(\d+(\.\d+)?)%/);
    //console.log('Scorematch: ' + scoreMatch);

    if (scoreMatch) {
      quizEndTime = new Date().toISOString();
      //console.log('Quiz end time: ', quizEndTime);

      const requestData = {
        grade: parseFloat(scoreMatch),
        start: quizStartTime,
        end: quizEndTime,
      };
      //console.log('requestData: ', JSON.stringify(requestData));

      fetch(
        `${process.env.REACT_APP_BACKEND_URL
        }/curriculum/add-quiz-attempt/${currentModuleId}/${parseInt(
          userId,
          10
        )}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          //console.log('Success:', data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });

      if (window.quizResultCallback) {
        window.quizResultCallback(parseFloat(scoreMatch));
      }

      activateKeyboardInput(sendBox, sendButton);
    }
  }
}

export function generateQuiz(
  user_id,
  module,
  module_id,
  topics,
  noQ,
  passScore,
  onQuizResult,
  onTranscript
) {
  if (isQuiz) {
    isQuiz = false;
    passingScore = passScore;
    quizQuestions = noQ;
    userId = user_id;
    current_module = module;
    currentModuleId = module_id;
    const userText = `Generate Quiz (${noQ}, ${passScore}%, ${module} covering ${topics}).`;
    //console.log('This is usertext: ' + userText);

    // Sofia
    // persona.conversationSend(userText, {}, {});

    // Heygen
    handleQuiz(userText);
    //console.log('[quiz] user wants to take quizzes on: ', module);

    transcript = [];
    transcript.push({ source: 'Learning', text: userText });
    chat.innerHTML = '';
    //console.log('[transcript]: ', transcript);

    quizStartTime = new Date().toISOString();
    //console.log('Quiz start time: ', quizStartTime);

    window.quizResultCallback = (score, moduleName, moduleId) => {
      onQuizResult(score, moduleName, moduleId);

      if (onTranscript) {
        onTranscript(transcript);
      }
    };
  }
}

export function generateIOA(user_id, topics, module = null, module_id = null) {
  let userText;
  userId = user_id;
  if (module && module_id) {
    current_module = module;
    currentModuleId = module_id;
    userText = `Begin IOA on module: ${module} covering topics: ${topics}.`;
  } else {
    userText = `Begin IOA on topic: ${topics}.`;
  }
  //console.log('This is usertext: ' + userText);


  // Sofia
  // persona.conversationSend(userText, {}, {});
  // Heygen
  handleIOA(userText);

  transcript.push({ source: 'Learning', text: userText });
  chat.innerHTML = '';
  //console.log('[transcript]: ', transcript);
}
