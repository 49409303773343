// IndexContext.js
import React, { createContext, useState } from 'react';

export const IndexContext = createContext();

export const IndexProvider = ({ children }) => {
  const [selectedIndex, setSelectedIndex] = useState('');

  return (
    <IndexContext.Provider value={{ selectedIndex, setSelectedIndex }}>
      {children}
    </IndexContext.Provider>
  );
};
