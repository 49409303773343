import React from 'react';
import './Modal.css';

const HelpGuideModal = ({ closeModal }) => {
  return (
    <>
      <div className="modal-background" onClick={closeModal}></div>
      <div className="help-modal">
        <div
          className="help-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <span className="help-close-button" onClick={closeModal}>
            <i className="fa-solid fa-x"></i>
          </span>
          <h2 style={{ fontFamily: 'Roboto, sans-serif' }}>Learning Path</h2>
          <p>
            The course content is organized into modules, with each module
            consisting of several topics. You are free to choose the order in
            which you study both the modules and the topics within them. A
            module is considered complete once you have passed the quiz and
            completed all the topics within the module. This flexible approach
            allows you to tailor your learning experience while ensuring mastery
            of key concepts through both the topics and the module quizzes.
          </p>
        </div>
      </div>
    </>
  );
};

export default HelpGuideModal;
