import React, { useState, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import StudentNavBar from '../components/StudentNavBar';
import ProfileModal from '../components/ProfileModal';
import SignOutButton from '../components/SignOutButton';
import { UserContext } from '../context/UserContext';
import MyCoursesListStud from '../components/MyCoursesListStud';
import CourseContent from '../components/CourseContent';
import { Dropdown } from 'react-bootstrap';
import { DarkModeContext } from '../context/DarkModeContext';
import './style.css';

const MyProgress = () => {
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const { user } = useContext(UserContext);
  const [sortOption, setSortOption] = useState('Latest Learned');
  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);

  useEffect(() => {
    const storedCourse = sessionStorage.getItem('selectedCourse');
    if (storedCourse) {
      setSelectedCourse(JSON.parse(storedCourse));
    }
  }, []);

  const handleProfileClick = () => {
    setIsProfileModalOpen(true);
  };

  const handleCloseProfileModal = () => {
    setIsProfileModalOpen(false);
  };

  const handleCourseSelect = (course) => {
    setSelectedCourse(course);
    if (course) {
      sessionStorage.setItem('selectedCourse', JSON.stringify(course));
    } else {
      sessionStorage.removeItem('selectedCourse');
    }
  };

  return (
    <div className="student-dashboard-container" id="myProgress-container">
      <div className="student-view-top-container">
        <div className="logo-container">
          <NavLink to="/">
            <img
              src={
                isDarkMode ? '/Sofia-logo-white.png' : '/Sofia-logo-colour.png'
              }
              alt="Logo"
              className="logo"
              draggable="false"
            />
          </NavLink>
        </div>
        <div className="nav-bar-container">
          <StudentNavBar />
        </div>

        <div className="profile-container">
          <button className="profile-user-button" onClick={handleProfileClick}>
            {user.profileImage ? (
              <img
                src={user.profileImage}
                referrerPolicy="no-referrer"
                className="profile-img"
              />
            ) : (
              <i
                className="fa-solid fa-user"
                style={{ fontSize: '25px', paddingRight: '15px' }}
              ></i>
            )}
            <span>{user.username}</span>
          </button>
        </div>

        <div className="signout-container">
          <SignOutButton />
        </div>
      </div>

      <div className="content-container">
        {selectedCourse ? (
          <div className="course-content-container" style={{ margin: '20px' }}>
            <button
              className="back-to-course-redirect-button z-index-button"
              onClick={() => {
                handleCourseSelect(null);
              }}
            >
              <i className="fa-solid fa-chevron-left"></i> Back
            </button>
            <CourseContent selectedCourse={selectedCourse} />
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <p className="page-title">My Courses</p>
              <Dropdown>
                <Dropdown.Toggle
                  variant="secondary"
                  id="course-filter-dropdown"
                  className="sort-dropdown"
                >
                  Sort by: {sortOption}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => setSortOption('Latest Learned')}
                  >
                    Latest Learned
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => setSortOption('Alphabetical')}>
                    Alphabetical Order
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <MyCoursesListStud
              onCourseSelect={handleCourseSelect}
              sortOption={sortOption}
            />
          </>
        )}
      </div>

      <ProfileModal
        isOpen={isProfileModalOpen}
        onClose={handleCloseProfileModal}
      />
    </div>
  );
};

export default MyProgress;
