import React, { useContext, useState, useEffect } from 'react';
import {
  Table,
  Row,
  Col,
  Container,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Dropdown,
} from 'react-bootstrap';
import EngagementChart from './EngagementChart';
import TimeAttemptsChart from './TimeAttemptsChart.js';
import LineGraph from './LineGraph';
import { DarkModeContext } from '../context/DarkModeContext';

import './dashboard.css';

const QuizAnalytics = ({ courseId, courseData }) => {
  const [engagementData, setEngagementData] = useState([]);
  const [quizScoreData, setQuizScoreData] = useState([]);
  const [passRateData, setPassRateData] = useState([]);
  const [timeSpentData, setTimeSpentData] = useState([]);
  const [quizAttemptsData, setQuizAttemptsData] = useState([]);
  const [attemptsLabels, setAttemptsLabels] = useState([]);
  const [attemptsDataPoints, setAttemptsDataPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasData, setHasData] = useState(false);
  const [selectedRange, setSelectedRange] = useState(120);
  const [columnsToShow, setColumnsToShow] = useState(3);

  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);

  const handleColumnSelection = (colCount) => {
    setColumnsToShow(colCount);
  };
  useEffect(() => {
    const resetData = () => {
      setEngagementData([]);
      setQuizScoreData([]);
      setPassRateData([]);
      setTimeSpentData([]);
      setQuizAttemptsData([]);
      setAttemptsLabels([]);
      setAttemptsDataPoints([]);
      setLoading(true);
      setHasData(false);
    };

    resetData();

    if (!courseData || !Array.isArray(courseData.modules)) {
      setLoading(false);
      return;
    }

    prepareData();
  }, [courseId, courseData]);

  const prepareData = () => {
    if (courseData.modules.length === 0) {
      setLoading(false);
      return;
    }

    prepareEngagementData(courseData);
    prepareQuizScoreData(courseData);
    preparePassRateData(courseData);
    prepareTimeSpentData(courseData);
    prepareQuizAttemptsData(courseData);

    setLoading(false);
    setHasData(true);
  };

  useEffect(() => {
    const fetchAttemptsData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/analytics/quiz-attempts-last-120-days/${courseId}`
        );
        const data = await response.json();

        if (data && data.start_date) {
          const newLabels = [];
          const dataPoints = data.data_points;
          const startDate = new Date(data.start_date);

          for (let i = 0; i < dataPoints.length; i++) {
            const currentDate = new Date(startDate);
            currentDate.setUTCDate(currentDate.getUTCDate() + i);
            const formattedDate = `${currentDate.getFullYear()}-${String(
              currentDate.getMonth() + 1
            ).padStart(2, '0')}-${String(currentDate.getDate()).padStart(
              2,
              '0'
            )}`;
            newLabels.push(formattedDate);
          }

          setAttemptsLabels(newLabels);
          setAttemptsDataPoints(dataPoints);
        } else {
          console.error('No valid start date returned.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAttemptsData();
  }, [courseId]);

  const prepareEngagementData = (courseData) => {
    const labels = courseData.modules.map(
      (module, index) => `${index + 1}. ${module.module_name}`
    );
    const chartData = courseData.modules.map((module) =>
      module.quiz_completion_rate.toFixed(2)
    );

    setEngagementData({ labels, chartData });
  };

  const prepareQuizScoreData = (courseData) => {
    const labels = courseData.modules.map(
      (module, index) => `${index + 1}. ${module.module_name}`
    );
    const chartData = courseData.modules.map((module) =>
      module.avg_quiz_score.toFixed(2)
    );

    setQuizScoreData({ labels, chartData });
  };

  const preparePassRateData = (courseData) => {
    const labels = courseData.modules.map(
      (module, index) => `${index + 1}. ${module.module_name}`
    );
    const chartData = courseData.modules.map((module) =>
      module.pass_rate.toFixed(2)
    );

    setPassRateData({ labels, chartData });
  };

  const prepareTimeSpentData = (courseData) => {
    const labels = courseData.modules.map(
      (module, index) => `${index + 1}. ${module.module_name}`
    );
    const chartData = courseData.modules.map((module) =>
      module.avg_quiz_time_spent.toFixed(2)
    );

    setTimeSpentData({ labels, chartData });
  };

  const prepareQuizAttemptsData = (courseData) => {
    const labels = courseData.modules.map(
      (module, index) => `${index + 1}. ${module.module_name}`
    );
    const chartData = courseData.modules.map((module) =>
      module.avg_no_of_quiz_attempts.toFixed(2)
    );

    setQuizAttemptsData({ labels, chartData });
  };

  if (!hasData) {
    return (
      <div style={{ textAlign: 'center' }}>
        <p style={{ color: '#777777', fontSize: '18px', marginBottom: '0' }}>
          No quiz analytics data available
        </p>
      </div>
    );
  }

  const filteredLabels = attemptsLabels.slice(-selectedRange - 1);
  const filteredDataPoints = attemptsDataPoints.slice(-selectedRange - 1);

  const Link = ({ id, children, title }) => (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id={id}>{title}</Tooltip>}
    >
      <a href="#">{children}</a>
    </OverlayTrigger>
  );

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <div className="quiz-analytics" style={{ textAlign: 'left' }}>
      <Container fluid style={{ margin: '0' }}>
        <Row className="align-items-center justify-content-between">
          <Col>
            <p
              style={{
                color: '#666666',
                textAlign: 'left',
                fontSize: '21px',
                fontWeight: 'bold',
                margin: '0',
              }}
            >
              Quiz Analytics
            </p>
          </Col>
          <Col xs="auto">
            <Dropdown>
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn-sm rounded-pill px-3"
              >
                {' '}
                <i
                  class="fa-solid fa-angle-down"
                  style={{ paddingRight: '10px' }}
                ></i>
                Show {columnsToShow} Columns
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleColumnSelection(3)}>
                  Show 3 Columns
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleColumnSelection(5)}>
                  Show All Columns
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>

        {hasData && (
          <>
            <Table responsive striped bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>Module</th>
                  {columnsToShow >= 3 && (
                    <>
                      <th>
                        Quiz Engagement Rate (%)
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip>
                              Engagement Rate (%) indicates the percentage of
                              students who have attempted the quiz.
                            </Tooltip>
                          }
                        >
                          <i className="fa-solid fa-circle-info"></i>
                        </OverlayTrigger>
                      </th>
                      <th>Pass Rate (%)</th>
                    </>
                  )}
                  {columnsToShow === 5 && (
                    <>
                      <th>Average Time Spent (mins)</th>
                      <th>Average Attempts</th>
                      <th>Average Quiz Score (%)</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {courseData.modules.map((module, moduleIndex) => (
                  <tr key={module.module_id}>
                    <td>
                      <strong>
                        {moduleIndex + 1}. {module.module_name}
                      </strong>
                    </td>
                    {columnsToShow >= 3 && (
                      <>
                        <td
                          style={{
                            color: isDarkMode
                              ? 'rgba(227 ,122 ,200 , 0.8)'
                              : 'rgba(139, 0, 103, 0.8)',
                            fontWeight: 'bold',
                          }}
                        >
                          {module.quiz_completion_rate.toFixed(2)}%
                        </td>
                        <td
                          style={{
                            color: isDarkMode
                              ? 'rgba(227 ,122 ,200 , 0.8)'
                              : 'rgba(139, 0, 103, 0.8)',
                            fontWeight: 'bold',
                          }}
                        >
                          {module.pass_rate.toFixed(2)}%
                        </td>
                      </>
                    )}
                    {columnsToShow === 5 && (
                      <>
                        <td
                          style={{
                            color: isDarkMode
                              ? ' rgba(227 ,122 ,200 , 0.8)'
                              : 'rgba(139, 0, 103, 0.8)',
                            fontWeight: 'bold',
                          }}
                        >
                          {module.avg_quiz_time_spent.toFixed(2)}
                        </td>
                        <td
                          style={{
                            color: isDarkMode
                              ? 'rgba(227 ,122 ,200 , 0.8)'
                              : 'rgba(139, 0, 103, 0.8)',
                            fontWeight: 'bold',
                          }}
                        >
                          {module.avg_no_of_quiz_attempts.toFixed(2)}
                        </td>
                        <td
                          style={{
                            color: isDarkMode
                              ? 'rgba(227 ,122 ,200 , 0.8)'
                              : 'rgba(139, 0, 103, 0.8)',
                            fontWeight: 'bold',
                          }}
                        >
                          {module.avg_quiz_score.toFixed(2)}%
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>

            <Table responsive striped bordered hover className="mt-3">
              <tbody>
                <tr>
                  <td
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'right',
                      paddingRight: '10px',
                    }}
                  >
                    Overall Quiz Engagement Rate
                  </td>
                  <td>{courseData.overall_quiz_completion_rate.toFixed(2)}%</td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'right',
                      paddingRight: '10px',
                    }}
                  >
                    Overall Pass Rate
                  </td>
                  <td>{courseData.overall_pass_rate.toFixed(2)}%</td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'right',
                      paddingRight: '10px',
                    }}
                  >
                    Overall Average Time Spent
                  </td>
                  <td>
                    {courseData.overall_avg_quiz_time_spent.toFixed(2)} minutes
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'right',
                      paddingRight: '10px',
                    }}
                  >
                    Overall Average Attempts
                  </td>
                  <td>
                    {courseData.overall_avg_no_of_quiz_attempts.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'right',
                      paddingRight: '10px',
                    }}
                  >
                    Overall Average Quiz Score
                  </td>
                  <td>{courseData.overall_avg_quiz_score.toFixed(2)}%</td>
                </tr>
              </tbody>
            </Table>
            <Row className="mt-4">
              <Col lg={6} md={6} xs={12}>
                {engagementData.labels.length > 0 &&
                  engagementData.chartData.length > 0 && (
                    <EngagementChart
                      key={courseId + '-engagementQuiz'}
                      labels={engagementData.labels}
                      chartData={engagementData.chartData}
                      title="Quiz Engagement Rates"
                      label="Modules"
                      text="Quiz Engagement Rate (%)"
                    />
                  )}
              </Col>
              <Col lg={6} md={6} xs={12}>
                {quizScoreData.labels.length > 0 &&
                  quizScoreData.chartData.length > 0 && (
                    <EngagementChart
                      key={courseId + '-score'}
                      labels={quizScoreData.labels}
                      chartData={quizScoreData.chartData}
                      title="Average Quiz Scores"
                      label="Modules"
                      text="Average Quiz Score (%)"
                    />
                  )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} xs={12}>
                {passRateData.labels.length > 0 &&
                  passRateData.chartData.length > 0 && (
                    <EngagementChart
                      key={courseId + '-passrate'}
                      labels={passRateData.labels}
                      chartData={passRateData.chartData}
                      title="Quiz Pass Rates"
                      label="Modules"
                      text="Pass Rate (%)"
                    />
                  )}
              </Col>
              <Col lg={6} md={6} xs={12}>
                {timeSpentData.labels.length > 0 &&
                  timeSpentData.chartData.length > 0 && (
                    <TimeAttemptsChart
                      key={courseId + '-timespent'}
                      labels={timeSpentData.labels}
                      chartData={timeSpentData.chartData}
                      title="Average Time Spent"
                      label="Modules"
                      text="Average Time Spent (mins)"
                      yValue="minutes"
                    />
                  )}
              </Col>
            </Row>
            <Row className="h-100">
              <Col
                lg={6}
                md={6}
                xs={12}
                className="d-flex flex-column justify-content-end"
              >
                {quizAttemptsData.labels &&
                  quizAttemptsData.labels.length > 0 &&
                  quizAttemptsData.chartData &&
                  quizAttemptsData.chartData.length > 0 && (
                    <TimeAttemptsChart
                      key={courseId + '-attemptsBar'}
                      labels={quizAttemptsData.labels}
                      chartData={quizAttemptsData.chartData}
                      title="Average Quiz Attempts"
                      label="Modules"
                      text="Average Attempts"
                      yValue="attempts"
                    />
                  )}
              </Col>
              <Col
                lg={6}
                md={6}
                xs={12}
                className="d-flex flex-column justify-content-end"
              >
                {attemptsLabels &&
                  attemptsLabels.length > 0 &&
                  attemptsDataPoints &&
                  attemptsDataPoints.length > 0 && (
                    <>
                      <Dropdown className="align-self-end me-2 mt-2 mb-1 me-md-0 me-lg-0">
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic"
                          className="btn-sm rounded-pill px-3"
                        >
                          {' '}
                          <i
                            class="fa-solid fa-angle-down"
                            style={{ paddingRight: '10px' }}
                          ></i>
                          Last {selectedRange} Days
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setSelectedRange(7)}>
                            7 Days
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setSelectedRange(14)}>
                            14 Days
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setSelectedRange(30)}>
                            30 Days
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setSelectedRange(60)}>
                            60 Days
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setSelectedRange(120)}>
                            120 Days
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      <LineGraph
                        key={courseId + '-attemptsLine'}
                        labels={filteredLabels}
                        dataPoints={filteredDataPoints}
                        chartLabel="Number of Quiz Attempts"
                        chartTitle={`Quiz Attempts Over Last ${selectedRange} Days`}
                        yValue="attempts"
                      />
                    </>
                  )}
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
};

export default QuizAnalytics;
