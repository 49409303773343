import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  Row,
  Col,
  Spinner,
  ProgressBar,
  Dropdown,
  Button,
} from 'react-bootstrap';
import './cards.css';
import { UserContext } from '../context/UserContext';
import { DarkModeContext } from '../context/DarkModeContext';

const MyCoursesListStud = ({ onCourseSelect, sortOption }) => {
  const [courses, setCourses] = useState([]);
  const [courseLoading, setCourseLoading] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const { user } = useContext(UserContext);
  const [hiddenCourses, setHiddenCourses] = useState([]);
  const [showHiddenCourses, setShowHiddenCourses] = useState(false);
  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);

  // useEffect(() => {
  //   const storedHiddenCourses = localStorage.getItem('hiddenCourses');
  //   if (storedHiddenCourses) {
  //     setHiddenCourses(JSON.parse(storedHiddenCourses));
  //   }
  // }, []);

  // //temporary
  // useEffect(() => {
  //   // Clear hidden courses on page load (refresh)
  //   localStorage.removeItem('hiddenCourses');
  //   setHiddenCourses([]); // Ensure the state is cleared
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem('hiddenCourses', JSON.stringify(hiddenCourses));
  // }, [hiddenCourses]);

  useEffect(() => {
    const fetchMyCourses = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/curriculum/courses/student/${user.id}`
        );

        if (response.ok) {
          const data = await response.json();
          setCourses(data.courses.filter((course) => !course.hidden));
          setHiddenCourses(data.courses.filter((course) => course.hidden));
        } else {
          console.error('Error fetching courses');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setCourseLoading(false);
      }
    };

    fetchMyCourses();
  }, [user.id]);

  const sortedCourses = () => {
    if (sortOption === 'Latest Learned') {
      return [...courses].sort(
        (a, b) => new Date(b.lastLearned) - new Date(a.lastLearned)
      );
    } else if (sortOption === 'Alphabetical') {
      return [...courses].sort((a, b) =>
        a.course_name.localeCompare(b.course_name)
      );
    }
    return courses; // Default order
  };

  const handleResetProgress = async (courseId) => {
    const isConfirmed = window.confirm(
      'Are you sure you want to reset your progress for this course? Your oral quiz records will be retained.'
    );

    if (!isConfirmed) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/reset-progress/${courseId}/${user.id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        alert(data.message);
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Error resetting progress:', error);
      alert('An error occurred while resetting the progress');
    }
  };

  const handleHideCourse = (courseId) => {
    const hiddenCourse = courses.find((course) => course.id === courseId);

    if (
      hiddenCourse &&
      !hiddenCourses.some((course) => course.id === courseId)
    ) {
      setHiddenCourses((prevHiddenCourses) => [
        ...prevHiddenCourses,
        hiddenCourse,
      ]);
      setCourses((prevCourses) =>
        prevCourses.filter((course) => course.id !== courseId)
      );
    }
  };

  const handleUnhideCourse = (courseId) => {
    toggleUnhide(courseId);
    const unhiddenCourse = hiddenCourses.find(
      (course) => course.id === courseId
    );

    if (unhiddenCourse && !courses.some((course) => course.id === courseId)) {
      setCourses((prevCourses) => {
        const updatedCourses = [...prevCourses, unhiddenCourse];

        return updatedCourses.sort((a, b) => a.id - b.id);
      });
      setHiddenCourses((prevHiddenCourses) =>
        prevHiddenCourses.filter((course) => course.id !== courseId)
      );
    }
  };

  const toggleHiddenCourses = () => {
    setShowHiddenCourses((prevState) => !prevState);
  };

  const getButtonLabel = (course) => {
    if (!course.has_started && course.completion_rate === 0) {
      return 'Start';
    } else if (
      course.has_started &&
      course.completion_rate >= 0 &&
      course.completion_rate < 100
    ) {
      return 'Resume';
    } else if (course.has_started && course.completion_rate === 100) {
      return 'Review';
    }
  };

  const toggleHide = async (course_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/hide-course/${course_id}`,
        {
          method: 'POST',
          body: JSON.stringify({
            hide: 'True',
            student_id: user.id
          }),
        }
      );
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const toggleUnhide = async (course_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/hide-course/${course_id}`,
        {
          method: 'POST',
          body: JSON.stringify({
            hide: 'False',
            student_id: user.id
          }),
        }
      );
    } catch (error) {
      console.error('Error:', error);
    }
  };

  if (courseLoading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <div className="my-courses-list">
      <Row className="mt-3">
        {sortedCourses().length === 0 ? (
          <p>No courses available</p>
        ) : (
          sortedCourses().map((course) => (
            <Col key={course.id} xs={12} md={4} className="mb-4">
              <Card
                className="p-2 h-100 d-flex flex-column"
                style={{ width: '100%' }}
              >
                <Card.Body className="flex-grow-1 d-flex flex-column">
                  <div className="mb-auto">
                    <Card.Title className="mb-3">
                      {course.course_name} {course.course_code}:{' '}
                      {course.description}
                    </Card.Title>
                    <Card.Subtitle className="mb-4 text-muted">
                      {course.semester <= 4
                        ? `Q${course.semester}`
                        : course.semester === 5
                        ? 'S1'
                        : 'S2'}{' '}
                      {course.year}
                    </Card.Subtitle>
                  </div>
                  <div className="w-100 mb-3 d-flex align-items-center">
                    <ProgressBar
                      variant="rating-bar"
                      now={course.completion_rate}
                      className="flex-grow-1"
                      style={{ height: '8px' }}
                    />
                    <span
                      className="ms-4"
                      style={{
                        fontSize: '14px',
                        color: '#777777',
                        fontWeight: 'bold',
                      }}
                    >
                      {course.completion_rate}%
                    </span>
                  </div>
                  <div className="mt-auto d-flex justify-content-end align-items-center">
                    <Card.Link
                      className="redo-icon"
                      onClick={() => handleResetProgress(course.id)}
                    >
                      <i className="fa-solid fa-rotate-right"></i>
                    </Card.Link>
                    <Card.Link>
                      <button
                        className="course-redirect-button"
                        onClick={() => {
                          setSelectedCourse(course);
                          onCourseSelect(course);
                        }}
                      >
                        {getButtonLabel(course)}
                      </button>
                    </Card.Link>
                  </div>
                  {/* ... */}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="link"
                      className="text-muted p-0 dropdown-no-caret"
                      style={{ fontSize: '20px' }}
                    >
                      <i class="fa-solid  fa-ellipsis"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => handleHideCourse(course.id)}
                      >
                        <div
                          className="hidden"
                          onClick={() => toggleHide(course.id)}
                        >
                          <i className="fa-solid fa-eye-slash icon-spacing"></i>
                          Hide Course
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Card.Body>
              </Card>
            </Col>
          ))
        )}
      </Row>
      {hiddenCourses.length > 0 && (
        <div className="hiddenCourses-wrapper mt-4 text-end">
          <button
            className="btn hiddenCourses"
            style={{
              border: `2px solid ${isDarkMode ? '#aaaaaa' : '#2b2b2b'}`,
              color: isDarkMode ? '#ffffff' : '#333',
              backgroundColor: isDarkMode ? '#2b2b2b' : 'transparent',
              padding: '8px 16px',
              borderRadius: '17px',
              cursor: 'pointer',
              transition:
                'background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease',
              fontFamily: "'Roboto', sans-serif",
              fontWeight: '500',
            }}
            onClick={toggleHiddenCourses}
          >
            <i
              className="fa-solid fa-box-archive"
              style={{
                color: isDarkMode ? '#ffffff' : '#333',
                marginRight: '8px',
                transition: 'color 0.3s ease',
              }}
            ></i>
            {showHiddenCourses ? 'Hide Hidden Courses' : 'View Hidden Courses'}
            <span style={{ marginLeft: '5px' }}>→</span>
          </button>
        </div>
      )}

      {/**/}
      {showHiddenCourses && hiddenCourses.length > 0 && (
        <div className="mt-4">
          <h5>Hidden Courses</h5>
          <Row>
            {hiddenCourses.map((course) => (
              <Col key={course.id} xs={12} md={4} className="mb-4">
                <Card
                  className="p-2 h-100 d-flex flex-column"
                  style={{ width: '100%' }}
                >
                  <Card.Body className="flex-grow-1 d-flex flex-column">
                    <div className="mb-auto">
                      <Card.Title className="mb-3">
                        {course.course_name} {course.course_code}:{' '}
                        {course.description}
                      </Card.Title>
                      <Card.Subtitle className="mb-4 text-muted">
                        {course.semester === 3
                          ? 'Summer School'
                          : `Semester ${course.semester}`}{' '}
                        {course.year}
                      </Card.Subtitle>
                    </div>
                    <div className="mt-auto d-flex justify-content-end align-items-center">
                      <button
                        className="unhide-course-button"
                        onClick={() => handleUnhideCourse(course.id)}
                      >
                        {' '}
                        <i className="fa-regular fa-eye"></i>
                        Unhide
                      </button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
};

export default MyCoursesListStud;
