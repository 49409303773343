import React, { useState, useEffect, useContext } from 'react';
import {
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Badge,
  Spinner,
  Dropdown,
} from 'react-bootstrap';
import FeedbackChart from './FeedbackChart';
import LineGraph from './LineGraph';
import CourseProgress from './CourseProgress';
import QuizAnalytics from './QuizAnalytics';
import './dashboard.css';
import { DarkModeContext } from '../context/DarkModeContext';

const DashboardAnalytics = ({ course }) => {
  const [feedbackData, setFeedbackData] = useState({
    accuracy: 'Loading...',
    usefulness: 'Loading...',
  });
  const [averageFeedback, setAverageFeedback] = useState({
    accuracy: null,
    usefulness: null,
  });

  const [accuracyCounts, setAccuracyCounts] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });
  const [usefulnessCounts, setUsefulnessCounts] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });
  const [uniqueStudentLabels, setUniqueStudentLabels] = useState([]);
  const [uniqueStudentDataPoints, setUniqueStudentDataPoints] = useState([]);
  const [selectedRange, setSelectedRange] = useState(120);
  const [recentFeedback, setRecentFeedback] = useState([]);
  const [courseData, setCourseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { isDarkMode } = useContext(DarkModeContext);

  const Link = ({ id, children, title }) => (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id={id}>{title}</Tooltip>}
    >
      <a href="#">{children}</a>
    </OverlayTrigger>
  );

  useEffect(() => {
    setFeedbackData({
      accuracy: 'Loading...',
      usefulness: 'Loading...',
    });
    setAverageFeedback({
      accuracy: null,
      usefulness: null,
    });
    setAccuracyCounts({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 });
    setUsefulnessCounts({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 });
    setUniqueStudentLabels([]);
    setUniqueStudentDataPoints([]);
    setRecentFeedback([]);
    setCourseData(null);
  }, [course]);

  // Fetch student logins for last 120 days
  useEffect(() => {
    const fetchUniqueUserData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/analytics/unique-users/${course.id}`
        );
        const data = await response.json();

        const newLabels = [];
        const dataPoints = data.data_points;
        const startDate = new Date(data.start_date);

        for (let i = 0; i < dataPoints.length; i++) {
          const currentDate = new Date(startDate);
          currentDate.setUTCDate(currentDate.getUTCDate() + i);
          const formattedDate = `${currentDate.getFullYear()}-${String(
            currentDate.getMonth() + 1
          ).padStart(2, '0')}-${String(currentDate.getDate()).padStart(
            2,
            '0'
          )}`;
          newLabels.push(formattedDate);
        }

        setUniqueStudentLabels(newLabels);
        setUniqueStudentDataPoints(dataPoints);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchUniqueUserData();
  }, [course.id]);

  // Fetch feedback data
  useEffect(() => {
    const fetchFeedbackData = async () => {
      if (!course) return;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/users/api/feedback/${course.id}`
        );
        if (response.ok) {
          const data = await response.json();
          const feedbacks = data.feedbacks;

          if (feedbacks.length > 0) {
            const totalAccuracy = feedbacks.reduce(
              (sum, feedback) => sum + feedback.accuracy_rating,
              0
            );
            const totalUsefulness = feedbacks.reduce(
              (sum, feedback) => sum + feedback.usefulness_rating,
              0
            );
            const feedbackCount = feedbacks.length;

            const accuracyAverage = (
              (totalAccuracy / feedbackCount / 5) *
              100
            ).toFixed(2);
            const usefulnessAverage = (
              (totalUsefulness / feedbackCount / 5) *
              100
            ).toFixed(2);

            const tempAccuracyCounts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
            const tempUsefulnessCounts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };

            feedbacks.forEach((feedback) => {
              tempAccuracyCounts[feedback.accuracy_rating] =
                (tempAccuracyCounts[feedback.accuracy_rating] || 0) + 1;
              tempUsefulnessCounts[feedback.usefulness_rating] =
                (tempUsefulnessCounts[feedback.usefulness_rating] || 0) + 1;
            });

            setAccuracyCounts(tempAccuracyCounts);
            setUsefulnessCounts(tempUsefulnessCounts);

            setFeedbackData({
              accuracy: `${accuracyAverage}%`,
              usefulness: `${usefulnessAverage}%`,
            });
          } else {
            setFeedbackData({
              accuracy: 'N/A',
              usefulness: 'N/A',
            });
          }
        } else {
          console.error('Error fetching feedback data');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchFeedbackData();
  }, [course]);

  useEffect(() => {
    const fetchAverageFeedback = async () => {
      if (!course) return;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/users/api/feedback/avg/${course.id}`
        );
        if (response.ok) {
          const data = await response.json();
          setAverageFeedback({
            accuracy:
              data.overall_avg_accuracy_rating !== null
                ? (data.overall_avg_accuracy_rating * 20).toFixed(2)
                : 'N/A',
            usefulness:
              data.overall_avg_usefulness_rating !== null
                ? (data.overall_avg_usefulness_rating * 20).toFixed(2)
                : 'N/A',
          });
        } else {
          console.error('Error fetching average feedback');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchAverageFeedback();
  }, [course]);

  useEffect(() => {
    const fetchRecentFeedbacks = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/users/api/recent-feedback/${course.id}`
        );
        if (response.ok) {
          const data = await response.json();
          setRecentFeedback(data.feedbacks);
        } else {
          console.error('Error fetching recent feedback');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchRecentFeedbacks();
  }, [course]);

  // Fetch course analytics
  useEffect(() => {
    setLoading(true);

    const fetchCourseData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/analytics/get-course-analytics/${course.id}`
        );
        const data = await response.json();
        if (data.data) {
          setCourseData(data.data);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseData();
  }, [course.id]);

  const timeAgo = (dateString) => {
    const now = new Date();
    const submittedDate = new Date(dateString);

    const seconds = Math.floor((now - submittedDate) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(seconds / 3600);
    const days = Math.floor(seconds / 86400);

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
    if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    }
    if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    }
    return 'Just now';
  };

  const filteredLabels = uniqueStudentLabels.slice(-selectedRange - 1);
  const filteredDataPoints = uniqueStudentDataPoints.slice(-selectedRange - 1);

  const cardStyle = {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    backgroundColor: isDarkMode ? 'transparent' : 'white',
    borderRadius: '20px',
    padding: '20px',
    marginBottom: '20px',
    border: isDarkMode
      ? '1px solid rgba(255, 255, 255, 0.1)'
      : '1px solid rgba(0, 0, 0, 0.1)',
    color: isDarkMode ? '#ffffff' : '#000000',
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <div className="analytics-container">
      <div style={cardStyle}>
        <CourseProgress courseId={course.id} courseData={courseData} />
      </div>

      <div style={cardStyle}>
        <QuizAnalytics courseId={course.id} courseData={courseData} />
      </div>

      <div style={cardStyle}>
        <Row>
          <Col xs={12} md={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                paddingLeft: '20px',
                paddingRight: '20px',
              }}
            >
              <p
                style={{
                  color: '#666666',
                  fontSize: '21px',
                  fontWeight: 'bold',
                }}
              >
                Feedback
              </p>
              <a
                href="/manage/feedback"
                style={{
                  color: '#777777',
                  textDecoration: 'none',
                  fontWeight: 'bold',
                }}
              >
                View More <i className="fa-solid fa-angle-right"></i>
              </a>
            </div>
            <Row>
              <Col xs={12} md={4}>
                <FeedbackChart
                  labels={['1', '2', '3', '4', '5']}
                  chartData={Object.values(accuracyCounts)}
                  title="Accuracy"
                  label="Rating"
                  text="Number of Feedback"
                />
                <div
                  style={{
                    marginTop: '6px',
                    fontSize: '15px',
                    textAlign: 'center',
                    color: '#777777',
                  }}
                >
                  Average (All Feedback): {feedbackData.accuracy}
                  <Link title="This average includes all feedback entries, regardless of whether they come from the same student.">
                    <i className="fa-solid fa-circle-info"></i>
                  </Link>
                  <br />
                  Average (Per Student):{' '}
                  {averageFeedback.accuracy
                    ? `${averageFeedback.accuracy}%`
                    : 'N/A'}
                  <Link title="Per Student Average: Computes average accuracy ratings for each student based on their feedback. Course Average: Averages these student averages to provide a balanced overall course rating, minimizing the effect of multiple feedbacks from a single student.">
                    <i className="fa-solid fa-circle-info"></i>
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <FeedbackChart
                  labels={['1', '2', '3', '4', '5']}
                  chartData={Object.values(usefulnessCounts)}
                  title="Usefulness"
                  label="Rating"
                  text="Number of Feedback"
                />
                <div
                  style={{
                    marginTop: '6px',
                    fontSize: '15px',
                    textAlign: 'center',
                    color: '#777777',
                  }}
                >
                  Average (All Feedback): {feedbackData.usefulness}
                  <Link title="This average includes all feedback entries, regardless of whether they come from the same student.">
                    <i className="fa-solid fa-circle-info"></i>
                  </Link>
                  <br />
                  Average (Per Student):{' '}
                  {averageFeedback.usefulness
                    ? `${averageFeedback.usefulness}%`
                    : 'N/A'}
                  <Link title="Per Student Average: Computes average usefulness ratings for each student based on their feedback. Course Average: Averages these student averages to provide a balanced overall course rating, minimizing the effect of multiple feedbacks from a single student.">
                    <i className="fa-solid fa-circle-info"></i>
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4} className="ps-4 mt-4 mt-md-0 mt-lg-0">
                {recentFeedback.length === 0 ? (
                  <p style={{ textAlign: 'center' }}>No feedback available</p>
                ) : (
                  recentFeedback.map((feedback) => (
                    <p key={feedback.id}>
                      <strong>
                        {feedback.student_first_name}{' '}
                        {feedback.student_last_name}
                      </strong>
                      <Badge pill bg="secondary" className="ms-2">
                        {timeAgo(feedback.submitted_at)}
                      </Badge>
                      <br />
                      {feedback.feedback_text.length > 110
                        ? `${feedback.feedback_text.substring(0, 110)}...`
                        : feedback.feedback_text}
                    </p>
                  ))
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div style={cardStyle}>
        <Dropdown className="float-end me-4 mb-1 me-md-0 me-lg-0">
          <Dropdown.Toggle
            variant="secondary"
            id="dropdown-basic"
            className="btn-sm rounded-pill px-3"
          >
            <i
              class="fa-solid fa-angle-down"
              style={{ paddingRight: '10px' }}
            ></i>
            Last {selectedRange} Days
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setSelectedRange(7)}>
              7 Days
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedRange(14)}>
              14 Days
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedRange(30)}>
              30 Days
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedRange(60)}>
              60 Days
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedRange(120)}>
              120 Days
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <LineGraph
          labels={filteredLabels}
          dataPoints={filteredDataPoints}
          chartLabel="Unique Student Count"
          chartTitle={`Student Login Over Last ${selectedRange} Days`}
          yValue="logins"
        />
      </div>
    </div>
  );
};

export default DashboardAnalytics;
